export const Appbar: React.FC<{}> = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        backgroundColor: "#262626",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </div>
  );
};
