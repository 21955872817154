import React, { useState, useEffect } from "react";
import {
  AuthenticationPayload,
  useApp_VerifyQuery,
} from "../generated/graphql";
import useCart, { CartItem } from "../hooks/useCart";

export const setSession = (session: AuthenticationPayload) => {
  try {
    localStorage.setItem("session", JSON.stringify(session));
  } catch (err) {
    console.log("Error parsing session ", err);
  }
};

export const getSession = (): AuthenticationPayload | undefined => {
  try {
    return JSON.parse(localStorage.getItem("session") || "{}");
  } catch (err) {
    return undefined;
  }
};

export const deleteSession = () => {
  localStorage.removeItem("session");
};

interface SessionContextState {
  loading?: boolean;
  session?: AuthenticationPayload | undefined;
  setSession: (session: AuthenticationPayload) => void;
  isLoggedIn?: boolean;
  logout: () => void;
  cartItems: CartItem[];
  setCartItems: (i: CartItem[]) => void;
}

export const UserContext = React.createContext<SessionContextState>({
  setSession,
  logout: () => {},
  cartItems: [],
  setCartItems: () => {},
});

export const UserProvider: React.FC<{}> = ({ children }) => {
  const [session, setSessionState] = useState<
    AuthenticationPayload | undefined
  >(getSession());
  const [loading, setLoading] = useState(true);
  const [cartItems, setCartItems] = useCart();

  const { loading: sessionLoading, error: sessionError } = useApp_VerifyQuery();

  useEffect(() => {
    if (!sessionLoading) {
      if (sessionError) {
        deleteSession();
      }
    }
  }, [sessionError, sessionLoading]);

  useEffect(() => {
    if (!sessionLoading) {
      setLoading(false);
    }
  }, [sessionLoading]);

  return (
    <UserContext.Provider
      value={{
        session,
        setSession: (session: AuthenticationPayload) => {
          setSessionState(session);
          setSession(session);
        },
        loading,
        logout: () => {
          setSessionState(undefined);
          deleteSession();
        },
        cartItems,
        setCartItems,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
