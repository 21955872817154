import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { getSession } from "../context/UserContext";
import uniqBy from "lodash.uniqby";

const contextLink = setContext(async () => {
  const session = getSession();
  if (session) {
    return {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    };
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 0,
  },
});

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

export const client = new ApolloClient({
  link: from([retryLink, contextLink, link]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          events: {
            keyArgs: false,
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                list: uniqBy(
                  [...(existing?.list || []), ...(incoming?.list || [])],
                  "id"
                ),
              };
            },
          },
          photos: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing?.id === incoming?.id
                ? {
                    ...existing,
                    ...incoming,
                    list: uniqBy(
                      [...(existing?.list || []), ...(incoming?.list || [])],
                      "id"
                    ),
                  }
                : incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { authorization: `Bearer ${getSession()?.token}` },
});

Axios.interceptors.request.use((req) => {
  if (req.headers)
    req.headers["authorization"] = `Bearer ${getSession()?.token}`;
  return req;
});
