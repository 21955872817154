import { styled } from "@mui/system";

const PhotosGrid = styled("div")({
  margin: "0 10vw",
  display: "grid",
  justifyContent: "space-evenly",
  justifyItems: "center",
  alignContent: "space-evenly",
  alignItems: "center",
  gridTemplateColumns: "calc(80vw/4) calc(80vw/4) calc(80vw/4) calc(80vw/4)",
  gridTemplateRows: "auto",
  boxSizing: "border-box",
  "@media (max-width: 1600px)": {
    margin: "0 5vw",
    gridTemplateColumns: "calc(70vw/3) calc(70vw/3) calc(70vw/3)",
  },
  "@media (max-width: 1300px)": {
    margin: "0 5vw",
    gridTemplateColumns: "calc(95vw/3) calc(95vw/3) calc(95vw/3)",
  },
  "@media (max-width: 980px)": {
    margin: "0 5vw",
    gridTemplateColumns: "calc(95vw/2) calc(95vw/2)",
  },
  "@media (max-width: 650px)": {
    margin: "0 5vw",
    gridTemplateColumns: "95vw",
  },
});

export default PhotosGrid;
