import React, { useRef, useState } from "react";
import fileSize from "filesize";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Check,
  Close,
  CloudUpload,
  DeleteForever,
  Image as ImageIcon,
  MoreVert,
  Refresh,
  Search,
} from "@mui/icons-material";
import { styled } from "@mui/system";

type RemoteFile = {
  id: string;
  name: string;
  url: string;
  size: number;
};

export type UploadFile = {
  localFile?: File;
  remoteFile?: RemoteFile;
};

type FileUploaderProps = {
  multiple?: boolean;
  buttonText?: string;
  files?: UploadFile[];
  file?: UploadFile;
  uploaded?: Record<string, RemoteFile>;
  errored?: Record<string, number>;
  loadingFileName?: string;
  onFilesChanged?: (files: UploadFile[]) => void;
  onRetryAllClick?: (files: UploadFile[]) => void;
  onFileChanged?: (file: UploadFile) => void;
  onFileClick?: (file: UploadFile, index?: number) => void;
  onDeleteClick?: (file: UploadFile) => void;
  onRetryClick?: (file: UploadFile) => void;
  onDeleteAllClick?: () => void;
};

const Container = styled("div")({
  width: "100%",
});

const UploadButton = styled(Button)({
  width: "100%",
  flex: 1,
});

const FileInput = styled("input")({
  opacity: 0,
  position: "absolute",
  zIndex: -1,
});

const ImagesList = styled(List)({
  minHeight: 500,
  height: 500,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const ImageText = styled(ListItemText)({
  flex: 1,
  color: "white",
});

const UploadContainer = styled("div")({
  marginBottom: 12,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const SingleFileLabel = styled("label")({
  color: "white",
});

const FileUploader: React.FC<FileUploaderProps> = ({
  files,
  file,
  onFilesChanged,
  onFileChanged,
  onDeleteAllClick,
  onRetryAllClick,
  onDeleteClick,
  onFileClick,
  onRetryClick,
  buttonText,
  multiple,
  uploaded = {},
  loadingFileName,
  errored,
}) => {
  const uploadImagesButtonRef = useRef<HTMLInputElement | null>(null);
  const [imagesSearch, setImagesSearch] = useState("");

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<null | HTMLElement>(null);
  if (!multiple) {
    return (
      <Container>
        {file ? (
          <List>
            <SingleFileLabel>{buttonText}</SingleFileLabel>
            <FileItem
              name={`${file.localFile?.name || file.remoteFile?.name}`}
              size={file.localFile?.size || file.remoteFile?.size || 0}
              onClick={() => onFileClick && onFileClick(file)}
              onDeleteClick={() => onDeleteClick && onDeleteClick(file)}
              onRetryClick={() => onRetryClick && onRetryClick(file)}
            />
          </List>
        ) : (
          <>
            <UploadButton
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              onClick={() => uploadImagesButtonRef.current?.click()}
            >
              {buttonText}
            </UploadButton>
            <FileInput
              ref={uploadImagesButtonRef}
              type="file"
              onChange={(e) => {
                onFileChanged &&
                  e.target.files &&
                  onFileChanged({ localFile: Array.from(e.target.files)[0] });
              }}
            />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <UploadContainer>
        <UploadButton
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={() => uploadImagesButtonRef.current?.click()}
        >
          {buttonText}
        </UploadButton>
        {files?.[0] ? (
          <>
            <IconButton
              onClick={(e) => setMoreOptionsAnchorEl(e.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={moreOptionsAnchorEl}
              keepMounted
              open={Boolean(moreOptionsAnchorEl)}
              onClose={() => {
                setMoreOptionsAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  onFilesChanged && onFilesChanged([]);
                  onDeleteAllClick && onDeleteAllClick();
                  setMoreOptionsAnchorEl(null);
                }}
              >
                Excluir todos
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onRetryAllClick &&
                    onRetryAllClick(
                      files.filter(
                        (i) => i.localFile && errored?.[i.localFile.name]
                      )
                    );
                  setMoreOptionsAnchorEl(null);
                }}
              >
                Reupar imagens falhadas
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </UploadContainer>
      {files?.[0] ? (
        <Input
          style={{ width: "100%" }}
          placeholder="Pesquisar"
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          value={imagesSearch}
          onChange={(e) => setImagesSearch(e.target.value)}
        />
      ) : null}
      <FileInput
        ref={uploadImagesButtonRef}
        type="file"
        multiple
        onChange={(e) => {
          if (onFilesChanged && e.target.files && files) {
            const localFiles = Array.from(e.target.files);
            const filteredFiles = files.reduce(
              (acc, i) =>
                localFiles.filter(
                  (j) =>
                    j.name === i.remoteFile?.name ||
                    j.name === i.localFile?.name
                )?.[0]
                  ? acc
                  : [...acc, i],
              [] as UploadFile[]
            );
            onFilesChanged([
              ...localFiles.map((i) => ({ localFile: i })),
              ...filteredFiles,
            ]);
          }
        }}
      />
      {files?.[0] ? (
        <ImagesList>
          {files
            .filter(
              (i) =>
                i.localFile?.name.includes(imagesSearch) ||
                i.remoteFile?.name.includes(imagesSearch)
            )
            .map((i, index) => {
              // const url = URL.createObjectURL(i);
              return (
                <FileItem
                  key={i.localFile?.name || i.remoteFile?.name}
                  uploaded={
                    !!i.remoteFile ||
                    (i.localFile && !!uploaded[i.localFile?.name])
                  }
                  loading={i.localFile && loadingFileName === i.localFile.name}
                  onClick={() => {
                    onFileClick && onFileClick(i, index);
                  }}
                  name={i.localFile?.name || i.remoteFile?.name || ""}
                  size={i.localFile?.size || i.remoteFile?.size || 0}
                  onRetryClick={() => onRetryClick && onRetryClick(i)}
                  onDeleteClick={() => {
                    onDeleteClick && onDeleteClick(i);
                    onFilesChanged &&
                      onFilesChanged(
                        files.filter(
                          (j) =>
                            i.localFile?.name !== j.localFile?.name ||
                            i.remoteFile?.name !== j.remoteFile?.name
                        )
                      );
                  }}
                  error={i.localFile && !!errored?.[i.localFile?.name]}
                />
              );
            })}
        </ImagesList>
      ) : null}
    </Container>
  );
};

const RetryButton = styled(IconButton)({
  marginRight: 18,
});

type FileItemProps = {
  name: string;
  size: number;
  onClick: () => void;
  onRetryClick: () => void;
  onDeleteClick: () => void;
  loading?: boolean;
  uploaded?: boolean;
  error?: boolean;
};

const FileItem: React.FC<FileItemProps> = ({
  name,
  size,
  onClick,
  onRetryClick,
  onDeleteClick,
  loading,
  uploaded,
  error,
}) => {
  return (
    <ListItem key={name} button onClick={onClick}>
      {uploaded ? (
        <ListItemIcon>
          <Check htmlColor="limegreen" />
        </ListItemIcon>
      ) : undefined}
      {loading && !uploaded && !error ? (
        <ListItemIcon>
          <CircularProgress size={32} />
        </ListItemIcon>
      ) : undefined}
      {!loading && !uploaded && !error ? (
        <ListItemIcon>
          <ImageIcon />
        </ListItemIcon>
      ) : undefined}
      {error ? (
        <ListItemIcon>
          <Close htmlColor="red" />
        </ListItemIcon>
      ) : undefined}

      <ImageText primary={name} secondary={fileSize(size)} />
      <ListItemSecondaryAction>
        {error ? (
          <RetryButton edge="end" onClick={onRetryClick}>
            <Refresh />
          </RetryButton>
        ) : undefined}
        <IconButton edge="end" onClick={onDeleteClick}>
          <DeleteForever />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FileUploader;
