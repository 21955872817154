import { Autocomplete, Box, TextField } from "@mui/material";
import { styled } from "@mui/system";
import {
  useConfigForm_ConfigQuery,
  useConfigForm_UpdateBannerEventConfigMutation,
} from "../../../generated/graphql";
import { getOption } from "../../../components/util/autocomplete";
import { useEffect, useState } from "react";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: 32,
});

const CTextField = styled(TextField)({
  width: 350,
  marginBottom: 18,
});

export const ConfigForm: React.FC = () => {
  const { data: config } = useConfigForm_ConfigQuery();
  const [updateEventBannerConfig] =
    useConfigForm_UpdateBannerEventConfigMutation();
  const [eventBannerId, setEventBannerId] = useState("");

  useEffect(() => {
    if (eventBannerId) {
      updateEventBannerConfig({ variables: { eventId: eventBannerId } });
    }
  }, [eventBannerId]);

  useEffect(() => {
    if (config?.config?.BannerEvent?.id)
      setEventBannerId(config.config.BannerEvent.id);
  }, [config?.config?.BannerEvent?.id]);

  return (
    <Box sx={{ width: "100%" }}>
      <Container>
        {config?.config ? (
          <Autocomplete
            disablePortal
            onChange={(e, value) => {
              if (value) setEventBannerId(value?.id);
            }}
            options={config?.events.list || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <CTextField
                {...params}
                label="Banner Evento Principal"
                // error={!!errors.cityId}
                // helperText={errors.cityId?.message}
              />
            )}
            // value={getOption(config?.events.list || [], eventBannerId)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            defaultValue={getOption(
              config?.events.list || [],
              config?.config?.BannerEvent?.id || ""
            )}
            disableClearable
          />
        ) : undefined}
      </Container>
    </Box>
  );
};
