import { styled } from "@mui/system";
import { Photo } from "../../../components/Photo";
import { useContext, useState } from "react";
import GalleryDialog from "../../../components/GalleryDialog";
import PhotosGrid from "../../../components/PhotosGrid";
import { Logo } from "../../../components/Logo";
import { CartItem } from "../../../hooks/useCart";
import PhotoCartButton from "../../../smartComponents/PhotoCartButton";
import { Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Appbar } from "../../../components/Appbar";
import UserContext from "../../../context/UserContext";

const EmptyText = styled("div")({
  width: "100%",
  padding: "48px 0",
  textAlign: "center",
  color: "white",
});

export const Cart = () => {
  const { cartItems, setCartItems } = useContext(UserContext);
  const [previewPhoto, setPreviewPhoto] = useState<number | undefined>();
  const navigate = useNavigate();

  return (
    <>
      <Appbar>
        <Toolbar style={{ width: "100%" }}>
          <Logo height={65} onClick={() => navigate("/")} />
          <div style={{ flex: 1 }} />
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "16px 32px",
              borderRadius: 32,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              const grouped = cartItems.reduce((acc, i) => {
                return {
                  [i.eventId]: [...(acc[i.eventId] || []), i],
                };
              }, {} as Record<string, CartItem[]>);
              const events = Object.entries(grouped).reduce(
                (acc, [key, value]) =>
                  acc +
                  `%0a%0a ${value?.[0].eventName} - ${value?.[0].eventDate} - ${
                    value?.[0].eventLocation
                  } ${value.reduce((acc1, i) => acc1 + "%0a- " + i.name, "")}`,
                ""
              );
              const greetings = "Olá! Gostaria de revelar estas fotos. %0a";

              window
                .open(
                  "https://api.whatsapp.com/send?phone=+554896342635&text=" +
                    greetings +
                    events,
                  "_blank"
                )
                ?.focus();
            }}
          >
            Enviar Pedido
          </div>
        </Toolbar>
      </Appbar>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }} />
        <div
          onClick={() => setCartItems([])}
          style={{ padding: 32, color: "white", cursor: "pointer" }}
        >
          Limpar
        </div>
      </div>
      <div style={{ paddingTop: 64 }}>
        {cartItems.length ? (
          <>
            <PhotosGrid key={(previewPhoto !== undefined).toString()}>
              {cartItems.map((i, ind) => (
                <Photo
                  key={i.id}
                  image={i.src}
                  onClick={() => setPreviewPhoto(ind)}
                />
              ))}
            </PhotosGrid>
          </>
        ) : (
          <EmptyText>Nenhuma foto registrada</EmptyText>
        )}
        {previewPhoto !== undefined ? (
          <GalleryDialog
            open={previewPhoto !== undefined}
            onDismiss={() => {
              setPreviewPhoto(undefined);
            }}
            images={
              cartItems.map((i) => ({
                url: i.src,
                name: i.name,
              })) || []
            }
            startIndex={previewPhoto}
            onIndexChanged={(ind) => setPreviewPhoto(ind)}
            RightHeaderIcons={<PhotoCartButton {...cartItems[previewPhoto]} />}
          />
        ) : null}
      </div>
    </>
  );
};
