import { styled } from "@mui/system";

const EventContainer = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
});

const EventImage = styled("img")({
  width: 300,
  height: 200,
  objectFit: "cover",
  borderRadius: 6,
  marginBottom: 18,
});

type PhotoProps = {
  image: string;

  onClick: () => void;
};

export const Photo: React.FC<PhotoProps> = ({ image, onClick }) => {
  return (
    <EventContainer onClick={onClick}>
      <EventImage src={image} />
    </EventContainer>
  );
};
