import { styled } from "@mui/system";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";

const CParallaxBanner = styled(ParallaxBanner)({
  aspectRatio: "4/1",
});

const BannerDarkener = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0,0,0,0.5)",
});

const BannerEventDetailsContainer = styled("div")({
  position: "absolute",
  left: 0,
  bottom: 0,
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  justifyContent: "center",
  margin: "28px 15vw",
});
const BannerEventDetailsTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BannerEventNameText = styled("a")({
  fontWeight: "bold",
  color: "white",
  fontSize: 48,
});

const BannerEventLocationTextContainer = styled("div")({
  fontWeight: "bold",
  color: "white",
  fontSize: 12,
  marginBottom: 6,
});

const BannerEventLocationTextLabel = styled("a")({
  fontWeight: "lighter",
  color: "lightgray",
});

const BannerEventPostedDateText = styled("a")({
  color: "white",
  fontSize: 12,
  fontWeight: "lighter",
});

type BannerProps = {
  image: string;
  name: string;
  location: string;
  date: string;
  onClick?: () => void;
};

export const Banner: React.FC<BannerProps> = ({
  image,
  name,
  location,
  date,
  onClick,
}) => {
  return (
    <ParallaxProvider>
      <CParallaxBanner
        style={{ height: 600 }}
        layers={[
          {
            image,
            speed: -20,
          },
        ]}
      >
        <BannerDarkener />
        <BannerEventDetailsContainer onClick={onClick}>
          <BannerEventDetailsTextContainer>
            <BannerEventNameText>{name}</BannerEventNameText>
            <BannerEventLocationTextContainer>
              <BannerEventLocationTextLabel>
                Local:{" "}
              </BannerEventLocationTextLabel>
              <a>{location}</a>
            </BannerEventLocationTextContainer>
            <BannerEventPostedDateText>
              Postado Em {date}
            </BannerEventPostedDateText>
          </BannerEventDetailsTextContainer>
        </BannerEventDetailsContainer>
      </CParallaxBanner>
    </ParallaxProvider>
  );
};
