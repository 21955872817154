import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spacer } from "../../../components/Spacer";
import {
  Photo,
  useEventForm_DeleteBannerPhotoFromEventMutation,
  useEventForm_DeletePhotoMutation,
  useEventForm_DeletePhotosFromEventMutation,
  useEventForm_DeleteThumbnailPhotoFromEventMutation,
  useEventForm_UpdateEventDraftMutation,
} from "../../../generated/graphql";
import FileUploader, { UploadFile } from "../../../components/FileUploader";
import { Axios } from "../../../api";
import GalleryDialog from "../../../components/GalleryDialog";
import { EventFormFormProps } from ".";
import PhotoDialog from "../../../components/PhotoDialog";

const ButtonsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const NextButton = styled(Button)({
  alignSelf: "flex-end",
});

const ImagesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 32,
});

const ImageUploadContainer = styled("div")({
  width: 400,
});
const Margin = styled("div")({
  height: 50,
});

type UploadStepProps = {
  eventId: string;
  onLoading: (b: boolean) => void;
  Photos: EventFormFormProps["Photos"];
  BannerPhoto: EventFormFormProps["BannerPhoto"];
  ThumbnailPhoto: EventFormFormProps["ThumbnailPhoto"];
};

export const UploadStep: React.FC<UploadStepProps> = ({
  eventId,
  Photos,
  BannerPhoto,
  ThumbnailPhoto,
  onLoading,
}) => {
  const navigate = useNavigate();

  const [deletePhoto] = useEventForm_DeletePhotoMutation();
  const [deletePhotosFromEvent] = useEventForm_DeletePhotosFromEventMutation();
  const [deleteThumbnailPhoto] =
    useEventForm_DeleteThumbnailPhotoFromEventMutation();
  const [deleteBannerPhoto] = useEventForm_DeleteBannerPhotoFromEventMutation();
  const [updateEventDraft] = useEventForm_UpdateEventDraftMutation();

  const [bannerPreview, setBannerPreview] = useState<string | undefined>();
  const [thumbnailPreview, setThumbnailPreview] = useState<
    string | undefined
  >();
  const [images, setImages] = useState<UploadFile[]>(
    Photos.map((i) => ({
      remoteFile: {
        ...i,
        url: `${process.env.REACT_APP_S3_URL}/photos/${i.id}.${i.extension}`,
      },
    }))
  );
  const [thumbnailPhoto, setThumbnailPhoto] = useState<UploadFile | undefined>(
    ThumbnailPhoto
      ? {
          remoteFile: {
            ...ThumbnailPhoto,
            url: `${process.env.REACT_APP_S3_URL}/photos/${ThumbnailPhoto.id}.${ThumbnailPhoto.extension}`,
          },
        }
      : undefined
  );
  const [bannerPhoto, setBannerPhoto] = useState<UploadFile | undefined>(
    BannerPhoto
      ? {
          remoteFile: {
            ...BannerPhoto,
            url: `${process.env.REACT_APP_S3_URL}/photos/${BannerPhoto.id}.${BannerPhoto.extension}`,
          },
        }
      : undefined
  );
  const [previewPhoto, setPreviewPhoto] = useState<number | undefined>();
  const [uploadedPhotos, setUploadedPhotos] = useState<
    Record<string, Exclude<UploadFile["remoteFile"], undefined>>
  >({});
  const [erroredPhotos, setErroredPhotos] = useState<Record<string, number>>(
    {}
  );
  const [loadingName, setLoadingName] = useState<string | undefined>();

  const upload = useCallback(
    async (files: UploadFile[]) => {
      onLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Accept", "application/json");

      for (let image of files) {
        if (image.localFile && !uploadedPhotos[image.localFile.name]) {
          try {
            const data = await uploadImage(image, eventId, "event");
            if (data) {
              setLoadingName(image.localFile.name);
              setUploadedPhotos((prev) => ({
                ...{
                  ...prev,
                  [data.name]: data,
                },
              }));
              setErroredPhotos((prev) =>
                Object.keys(prev)
                  .filter((i) => i !== data.name)
                  .reduce((acc, i) => ({ ...acc, [i]: 1 }), {})
              );
            }
          } catch (err) {
            if (image.localFile) {
              setErroredPhotos((prev) => ({
                ...{
                  ...prev,
                  [image.localFile?.name || ""]: 1,
                },
              }));
            }
          }
        }
      }
      onLoading(false);
      setLoadingName(undefined);
    },
    [eventId, onLoading, uploadedPhotos]
  );

  return (
    <>
      <ImagesContainer>
        <ImageUploadContainer>
          <FileUploader
            buttonText="Banner"
            file={bannerPhoto}
            onFileChanged={async (file) => {
              setBannerPhoto(file);
              const data = await uploadImage(file, eventId, "banner");
              setBannerPhoto({ remoteFile: data });
            }}
            onDeleteClick={() => {
              setBannerPhoto(undefined);
              deleteBannerPhoto({ variables: { eventId } });
            }}
            onFileClick={() => {
              if (bannerPhoto?.localFile) {
                setBannerPreview(URL.createObjectURL(bannerPhoto.localFile));
              } else if (bannerPhoto?.remoteFile) {
                setBannerPreview(bannerPhoto.remoteFile.url);
              }
            }}
          />
          <Margin />
          <FileUploader
            buttonText="Miniatura"
            file={thumbnailPhoto}
            onFileChanged={async (file) => {
              setThumbnailPhoto(file);
              const data = await uploadImage(file, eventId, "thumbnail");
              setThumbnailPhoto({ remoteFile: data });
            }}
            onDeleteClick={() => {
              setThumbnailPhoto(undefined);
              deleteThumbnailPhoto({ variables: { eventId } });
            }}
            onFileClick={() => {
              if (thumbnailPhoto?.localFile) {
                setBannerPreview(URL.createObjectURL(thumbnailPhoto.localFile));
              } else if (thumbnailPhoto?.remoteFile) {
                setBannerPreview(thumbnailPhoto.remoteFile.url);
              }
            }}
          />
          <Margin />
          <FileUploader
            multiple
            files={images}
            uploaded={uploadedPhotos}
            errored={erroredPhotos}
            loadingFileName={loadingName}
            onRetryClick={async (i) => {
              if (i.localFile) {
                onLoading(true);
                await upload([i]);
                onLoading(false);
              }
            }}
            onDeleteAllClick={async () => {
              onLoading(true);
              await deletePhotosFromEvent({ variables: { eventId } });
              setImages([]);
              onLoading(false);
            }}
            onRetryAllClick={async (f) => {
              onLoading(true);
              await upload(f);
              onLoading(false);
            }}
            onFilesChanged={async (files) => {
              setImages(files);
              await upload(files);
            }}
            buttonText="Fotos"
            onFileClick={(i, ind) => setPreviewPhoto(ind)}
            onDeleteClick={async (i) => {
              onLoading(true);
              if (i.remoteFile) {
                await deletePhoto({
                  variables: { deletePhotoId: i.remoteFile.id },
                });
                setImages(
                  images.filter(
                    (j) => j.remoteFile?.name !== i.remoteFile?.name
                  )
                );
              } else if (i.localFile && uploadedPhotos[i.localFile.name]) {
                await deletePhoto({
                  variables: {
                    deletePhotoId: uploadedPhotos[i.localFile.name].id,
                  },
                });
                setImages(
                  images.filter((j) => j.localFile?.name !== i.localFile?.name)
                );
              } else if (i.localFile) {
                setImages(
                  images.filter((j) => j.localFile?.name !== i.localFile?.name)
                );
              }
              onLoading(false);
            }}
          />
        </ImageUploadContainer>
        <ButtonsContainer>
          <Spacer />
          <NextButton
            onClick={async () => {
              await updateEventDraft({
                variables: { id: eventId, draft: false },
              });
              navigate("/admin/events");
            }}
          >
            Finalizar
          </NextButton>
        </ButtonsContainer>
      </ImagesContainer>
      {previewPhoto !== undefined ? (
        <GalleryDialog
          open={previewPhoto !== undefined}
          onDismiss={() => setPreviewPhoto(undefined)}
          images={images.map((i) => ({
            url: i.localFile
              ? URL.createObjectURL(i.localFile)
              : i.remoteFile?.url,
            name: i.localFile?.name || i.remoteFile?.name || "",
          }))}
          startIndex={previewPhoto}
        />
      ) : null}

      <PhotoDialog
        open={!!(bannerPreview || thumbnailPreview)}
        onDismiss={() => {
          setThumbnailPreview(undefined);
          setBannerPreview(undefined);
        }}
        src={bannerPreview || thumbnailPreview}
      />
    </>
  );
};

const uploadImage = async (
  image: UploadFile,
  eventId: string,
  type: "thumbnail" | "banner" | "event"
): Promise<Photo | undefined> => {
  if (image.localFile) {
    const formData = new FormData();

    formData.append(`file`, image.localFile);
    formData.append("name", image.localFile.name);
    formData.append("eventId", eventId);

    if (type === "thumbnail") {
      return (await Axios.post<Photo>("/uploadThumbnail", formData)).data;
    } else if (type === "banner") {
      return (await Axios.post<Photo>("/uploadBanner", formData)).data;
    } else {
      return (await Axios.post<Photo>("/upload", formData)).data;
    }
  }
};
