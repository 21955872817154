import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { PAGINATION_TAKE } from "../../util/pagination";
import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
export interface DataColumnConfig {
  field: string;
  headerName: string;
  width?: number;
}

interface DataTableProps {
  columns: DataColumnConfig[];
  rows: any[];
  size: number;
  skip: number;
  onRowClick?: (data: any) => void;
  onPageChange: (newPage: number) => void;
  onEditClick?: (row: any) => void;
  onDeleteClick?: (row: any) => void;
}

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  rows,
  size,
  skip,
  onRowClick,
  onPageChange,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((i) => (
                <TableCell key={i.headerName} width={i.width}>
                  {i.headerName}
                </TableCell>
              ))}
              {onEditClick ? <TableCell width={50} /> : undefined}
              {onDeleteClick ? <TableCell width={50} /> : undefined}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {columns.map((i) => (
                    <TableCell key={i.headerName} width={i.width}>
                      {row[i.field]}
                    </TableCell>
                  ))}
                  {onEditClick ? (
                    <TableCell>
                      <IconButton onClick={() => onEditClick(row)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  ) : undefined}
                  {onDeleteClick ? (
                    <TableCell>
                      <IconButton onClick={() => onDeleteClick(row)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  ) : undefined}
                </TableRow>
              );
            })}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[PAGINATION_TAKE]}
        component="div"
        count={size}
        rowsPerPage={PAGINATION_TAKE}
        page={skip / PAGINATION_TAKE}
        onPageChange={(_, newPage) => onPageChange(newPage)}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
