import logo from "../assets/lpf.png";

export const Logo: React.FC<{
  width?: number;
  height?: number;
  onClick?: () => void;
}> = ({ width, height, onClick }) => {
  return (
    <img
      src={logo}
      alt="Logo"
      style={{ margin: 12, cursor: "pointer" }}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};
