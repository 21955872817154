import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from "@mui/system";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PhotoDialogProps = {
  open: boolean;
  onDismiss: () => void;
  src?: string;
};

const CAppBar = styled(AppBar)({
  position: "absolute",
});
const CImage = styled("img")({
  marginTop: 75,
});

const PhotoDialog: React.FC<PhotoDialogProps> = ({ open, onDismiss, src }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onDismiss}
      TransitionComponent={Transition}
    >
      <CAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            // color="inherit"
            onClick={onDismiss}
          >
            <Close />
          </IconButton>
        </Toolbar>
      </CAppBar>
      <CImage src={src} />
    </Dialog>
  );
};

export default PhotoDialog;
