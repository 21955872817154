import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  DateTime: any;
};

export type AuthenticationPayload = {
  __typename?: 'AuthenticationPayload';
  token: Scalars['String'];
  User: User;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  miniaturePhotoUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CategoryList = {
  __typename?: 'CategoryList';
  size: Scalars['Int'];
  list: Array<Category>;
};

export type CategoryOrderByInput = {
  createdAt?: Maybe<Episode>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['ID'];
  name: Scalars['String'];
  Locations: Array<Location>;
  State: State;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Config = {
  __typename?: 'Config';
  BannerEvent?: Maybe<Event>;
};


export enum Episode {
  Asc = 'asc',
  Desc = 'desc'
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  name: Scalars['String'];
  date: Scalars['DateTime'];
  draft: Scalars['Boolean'];
  private: Scalars['Boolean'];
  Photos: Array<Photo>;
  ThumbnailPhoto?: Maybe<Photo>;
  BannerPhoto?: Maybe<Photo>;
  Location?: Maybe<Location>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventList = {
  __typename?: 'EventList';
  size: Scalars['Int'];
  list: Array<Event>;
};

export type EventOrderByInput = {
  createdAt?: Maybe<Episode>;
  date?: Maybe<Episode>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  City?: Maybe<City>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LocationList = {
  __typename?: 'LocationList';
  size: Scalars['Int'];
  list: Array<Location>;
};

export type LocationOrderByInput = {
  createdAt?: Maybe<Episode>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: AuthenticationPayload;
  upsertEvent: Event;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  deleteBannerPhotoFromEvent: Event;
  deleteThumbnailPhotoFromEvent: Event;
  addPhotoToEvent: Photo;
  deletePhoto: Photo;
  deletePhotosFromEvent?: Maybe<Scalars['Boolean']>;
  upsertLocation: Location;
  deleteLocation: Location;
  upsertCategory: Category;
  updateBannerEventConfig: Config;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpsertEventArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  bannerPhotoId?: Maybe<Scalars['String']>;
  thumbnailPhotoId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBannerPhotoFromEventArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteThumbnailPhotoFromEventArgs = {
  eventId: Scalars['String'];
};


export type MutationAddPhotoToEventArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  eventId: Scalars['String'];
  takenAt: Scalars['String'];
};


export type MutationDeletePhotoArgs = {
  id: Scalars['String'];
};


export type MutationDeletePhotosFromEventArgs = {
  eventId: Scalars['String'];
};


export type MutationUpsertLocationArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['String'];
};


export type MutationUpsertCategoryArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  miniaturePhotoUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateBannerEventConfigArgs = {
  eventId: Scalars['String'];
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  extension: Scalars['String'];
  size: Scalars['Int'];
  Event: Event;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PhotoList = {
  __typename?: 'PhotoList';
  id: Scalars['ID'];
  size: Scalars['Int'];
  list: Array<Photo>;
};

export type PhotoOrderByInput = {
  name?: Maybe<Episode>;
};

export type Query = {
  __typename?: 'Query';
  verify?: Maybe<Scalars['Boolean']>;
  events: EventList;
  event: Event;
  photos: PhotoList;
  location: Location;
  locations: LocationList;
  cities: Array<City>;
  categories: CategoryList;
  category: Category;
  config: Config;
};


export type QueryEventsArgs = {
  take: Scalars['Int'];
  skip: Scalars['Int'];
  draft?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<EventOrderByInput>;
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryPhotosArgs = {
  eventId: Scalars['String'];
  take: Scalars['Int'];
  skip: Scalars['Int'];
  orderBy?: Maybe<PhotoOrderByInput>;
};


export type QueryLocationArgs = {
  id: Scalars['String'];
};


export type QueryLocationsArgs = {
  take: Scalars['Int'];
  skip: Scalars['Int'];
  orderBy?: Maybe<LocationOrderByInput>;
};


export type QueryCategoriesArgs = {
  take: Scalars['Int'];
  skip: Scalars['Int'];
  orderBy?: Maybe<CategoryOrderByInput>;
};


export type QueryCategoryArgs = {
  id: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  id: Scalars['ID'];
  name: Scalars['String'];
  UF: Scalars['String'];
  Cities: Array<City>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  admin: Scalars['Boolean'];
  sysadmin: Scalars['Boolean'];
  photographer: Scalars['Boolean'];
};

export type App_VerifyQueryVariables = Exact<{ [key: string]: never; }>;


export type App_VerifyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verify'>
);

export type Login_LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type Login_LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthenticationPayload' }
    & Pick<AuthenticationPayload, 'token'>
    & { User: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'admin' | 'sysadmin' | 'photographer'>
    ) }
  ) }
);

export type ConfigForm_ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigForm_ConfigQuery = (
  { __typename?: 'Query' }
  & { config: (
    { __typename?: 'Config' }
    & { BannerEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  ), events: (
    { __typename?: 'EventList' }
    & Pick<EventList, 'size'>
    & { list: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )> }
  ) }
);

export type ConfigForm_UpdateBannerEventConfigMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type ConfigForm_UpdateBannerEventConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateBannerEventConfig: (
    { __typename?: 'Config' }
    & { BannerEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  ) }
);

export type EventForm_EventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EventForm_EventQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'date' | 'private'>
    & { Location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
    )>, Photos: Array<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'name' | 'extension' | 'size'>
    )>, BannerPhoto?: Maybe<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'name' | 'extension' | 'size'>
    )>, ThumbnailPhoto?: Maybe<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'name' | 'extension' | 'size'>
    )> }
  ) }
);

export type EventForm_LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventForm_LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationList' }
    & { list: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
      & { City?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name'>
        & { State: (
          { __typename?: 'State' }
          & Pick<State, 'id' | 'UF'>
        ) }
      )> }
    )> }
  ) }
);

export type EventForm_UpsertEventMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  private: Scalars['Boolean'];
  bannerPhotoId?: Maybe<Scalars['String']>;
  thumbnailPhotoId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
}>;


export type EventForm_UpsertEventMutation = (
  { __typename?: 'Mutation' }
  & { upsertEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) }
);

export type EventForm_UpdateEventDraftMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
}>;


export type EventForm_UpdateEventDraftMutation = (
  { __typename?: 'Mutation' }
  & { upsertEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) }
);

export type EventForm_AddPhotoToEventMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  eventId: Scalars['String'];
  takenAt: Scalars['String'];
}>;


export type EventForm_AddPhotoToEventMutation = (
  { __typename?: 'Mutation' }
  & { addPhotoToEvent: (
    { __typename?: 'Photo' }
    & Pick<Photo, 'id'>
  ) }
);

export type EventForm_DeletePhotosFromEventMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type EventForm_DeletePhotosFromEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePhotosFromEvent'>
);

export type EventForm_DeletePhotoMutationVariables = Exact<{
  deletePhotoId: Scalars['String'];
}>;


export type EventForm_DeletePhotoMutation = (
  { __typename?: 'Mutation' }
  & { deletePhoto: (
    { __typename?: 'Photo' }
    & Pick<Photo, 'id' | 'name'>
  ) }
);

export type EventForm_DeleteThumbnailPhotoFromEventMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type EventForm_DeleteThumbnailPhotoFromEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteThumbnailPhotoFromEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) }
);

export type EventForm_DeleteBannerPhotoFromEventMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type EventForm_DeleteBannerPhotoFromEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteBannerPhotoFromEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) }
);

export type Events_EventsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type Events_EventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventList' }
    & Pick<EventList, 'size'>
    & { list: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'draft' | 'date' | 'createdAt'>
    )> }
  ) }
);

export type Events_DeleteEventMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type Events_DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEvent'>
);

export type LocationForm_LocationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type LocationForm_LocationQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
    & { City?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id'>
    )> }
  ) }
);

export type LocationForm_CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationForm_CitiesQuery = (
  { __typename?: 'Query' }
  & { cities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
    & { State: (
      { __typename?: 'State' }
      & Pick<State, 'id' | 'UF'>
    ) }
  )> }
);

export type LocationForm_UpsertLocationMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  cityId: Scalars['String'];
}>;


export type LocationForm_UpsertLocationMutation = (
  { __typename?: 'Mutation' }
  & { upsertLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type Locations_LocationsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type Locations_LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationList' }
    & Pick<LocationList, 'size'>
    & { list: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'createdAt'>
      & { City?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name'>
        & { State: (
          { __typename?: 'State' }
          & Pick<State, 'id' | 'UF'>
        ) }
      )> }
    )> }
  ) }
);

export type Event_PhotosQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  eventId: Scalars['String'];
}>;


export type Event_PhotosQuery = (
  { __typename?: 'Query' }
  & { photos: (
    { __typename?: 'PhotoList' }
    & Pick<PhotoList, 'id' | 'size'>
    & { list: Array<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'name' | 'extension' | 'url'>
    )> }
  ) }
);

export type Event_EventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type Event_EventQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'date'>
    & { Location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
      & { City?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name'>
        & { State: (
          { __typename?: 'State' }
          & Pick<State, 'id' | 'UF'>
        ) }
      )> }
    )>, BannerPhoto?: Maybe<(
      { __typename?: 'Photo' }
      & Pick<Photo, 'id' | 'name' | 'extension' | 'size'>
    )> }
  ) }
);

export type Home_EventsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  draft?: Maybe<Scalars['Boolean']>;
}>;


export type Home_EventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventList' }
    & Pick<EventList, 'size'>
    & { list: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'date'>
      & { Location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
        & { City?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'id' | 'name'>
          & { State: (
            { __typename?: 'State' }
            & Pick<State, 'id' | 'UF'>
          ) }
        )> }
      )>, ThumbnailPhoto?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'name' | 'extension' | 'url'>
      )>, BannerPhoto?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'name' | 'extension' | 'url'>
      )> }
    )> }
  ) }
);

export type Home_ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type Home_ConfigQuery = (
  { __typename?: 'Query' }
  & { config: (
    { __typename?: 'Config' }
    & { BannerEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'date'>
      & { Location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
        & { City?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'id' | 'name'>
          & { State: (
            { __typename?: 'State' }
            & Pick<State, 'id' | 'UF'>
          ) }
        )> }
      )>, BannerPhoto?: Maybe<(
        { __typename?: 'Photo' }
        & Pick<Photo, 'id' | 'name' | 'extension' | 'url'>
      )> }
    )> }
  ) }
);


export const App_VerifyDocument = gql`
    query App_verify {
  verify
}
    `;

/**
 * __useApp_VerifyQuery__
 *
 * To run a query within a React component, call `useApp_VerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApp_VerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApp_VerifyQuery({
 *   variables: {
 *   },
 * });
 */
export function useApp_VerifyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<App_VerifyQuery, App_VerifyQueryVariables>) {
        return ApolloReactHooks.useQuery<App_VerifyQuery, App_VerifyQueryVariables>(App_VerifyDocument, baseOptions);
      }
export function useApp_VerifyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<App_VerifyQuery, App_VerifyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<App_VerifyQuery, App_VerifyQueryVariables>(App_VerifyDocument, baseOptions);
        }
export type App_VerifyQueryHookResult = ReturnType<typeof useApp_VerifyQuery>;
export type App_VerifyLazyQueryHookResult = ReturnType<typeof useApp_VerifyLazyQuery>;
export type App_VerifyQueryResult = ApolloReactCommon.QueryResult<App_VerifyQuery, App_VerifyQueryVariables>;
export const Login_LoginDocument = gql`
    mutation Login_login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    User {
      id
      email
      name
      admin
      sysadmin
      photographer
    }
  }
}
    `;
export type Login_LoginMutationFn = ApolloReactCommon.MutationFunction<Login_LoginMutation, Login_LoginMutationVariables>;

/**
 * __useLogin_LoginMutation__
 *
 * To run a mutation, you first call `useLogin_LoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogin_LoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginLoginMutation, { data, loading, error }] = useLogin_LoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogin_LoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Login_LoginMutation, Login_LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<Login_LoginMutation, Login_LoginMutationVariables>(Login_LoginDocument, baseOptions);
      }
export type Login_LoginMutationHookResult = ReturnType<typeof useLogin_LoginMutation>;
export type Login_LoginMutationResult = ApolloReactCommon.MutationResult<Login_LoginMutation>;
export type Login_LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<Login_LoginMutation, Login_LoginMutationVariables>;
export const ConfigForm_ConfigDocument = gql`
    query ConfigForm_config {
  config {
    BannerEvent {
      id
    }
  }
  events(skip: 0, take: 100, orderBy: {createdAt: desc}) {
    size
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useConfigForm_ConfigQuery__
 *
 * To run a query within a React component, call `useConfigForm_ConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigForm_ConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigForm_ConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigForm_ConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigForm_ConfigQuery, ConfigForm_ConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigForm_ConfigQuery, ConfigForm_ConfigQueryVariables>(ConfigForm_ConfigDocument, baseOptions);
      }
export function useConfigForm_ConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigForm_ConfigQuery, ConfigForm_ConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigForm_ConfigQuery, ConfigForm_ConfigQueryVariables>(ConfigForm_ConfigDocument, baseOptions);
        }
export type ConfigForm_ConfigQueryHookResult = ReturnType<typeof useConfigForm_ConfigQuery>;
export type ConfigForm_ConfigLazyQueryHookResult = ReturnType<typeof useConfigForm_ConfigLazyQuery>;
export type ConfigForm_ConfigQueryResult = ApolloReactCommon.QueryResult<ConfigForm_ConfigQuery, ConfigForm_ConfigQueryVariables>;
export const ConfigForm_UpdateBannerEventConfigDocument = gql`
    mutation ConfigForm_updateBannerEventConfig($eventId: String!) {
  updateBannerEventConfig(eventId: $eventId) {
    BannerEvent {
      id
    }
  }
}
    `;
export type ConfigForm_UpdateBannerEventConfigMutationFn = ApolloReactCommon.MutationFunction<ConfigForm_UpdateBannerEventConfigMutation, ConfigForm_UpdateBannerEventConfigMutationVariables>;

/**
 * __useConfigForm_UpdateBannerEventConfigMutation__
 *
 * To run a mutation, you first call `useConfigForm_UpdateBannerEventConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigForm_UpdateBannerEventConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configFormUpdateBannerEventConfigMutation, { data, loading, error }] = useConfigForm_UpdateBannerEventConfigMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useConfigForm_UpdateBannerEventConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigForm_UpdateBannerEventConfigMutation, ConfigForm_UpdateBannerEventConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigForm_UpdateBannerEventConfigMutation, ConfigForm_UpdateBannerEventConfigMutationVariables>(ConfigForm_UpdateBannerEventConfigDocument, baseOptions);
      }
export type ConfigForm_UpdateBannerEventConfigMutationHookResult = ReturnType<typeof useConfigForm_UpdateBannerEventConfigMutation>;
export type ConfigForm_UpdateBannerEventConfigMutationResult = ApolloReactCommon.MutationResult<ConfigForm_UpdateBannerEventConfigMutation>;
export type ConfigForm_UpdateBannerEventConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfigForm_UpdateBannerEventConfigMutation, ConfigForm_UpdateBannerEventConfigMutationVariables>;
export const EventForm_EventDocument = gql`
    query EventForm_event($id: String!) {
  event(id: $id) {
    id
    name
    date
    private
    Location {
      id
    }
    Photos {
      id
      name
      extension
      size
    }
    BannerPhoto {
      id
      name
      extension
      size
    }
    ThumbnailPhoto {
      id
      name
      extension
      size
    }
  }
}
    `;

/**
 * __useEventForm_EventQuery__
 *
 * To run a query within a React component, call `useEventForm_EventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventForm_EventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventForm_EventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventForm_EventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventForm_EventQuery, EventForm_EventQueryVariables>) {
        return ApolloReactHooks.useQuery<EventForm_EventQuery, EventForm_EventQueryVariables>(EventForm_EventDocument, baseOptions);
      }
export function useEventForm_EventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventForm_EventQuery, EventForm_EventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventForm_EventQuery, EventForm_EventQueryVariables>(EventForm_EventDocument, baseOptions);
        }
export type EventForm_EventQueryHookResult = ReturnType<typeof useEventForm_EventQuery>;
export type EventForm_EventLazyQueryHookResult = ReturnType<typeof useEventForm_EventLazyQuery>;
export type EventForm_EventQueryResult = ApolloReactCommon.QueryResult<EventForm_EventQuery, EventForm_EventQueryVariables>;
export const EventForm_LocationsDocument = gql`
    query EventForm_locations {
  locations(skip: 0, take: 1000, orderBy: {createdAt: desc}) {
    list {
      id
      name
      City {
        id
        name
        State {
          id
          UF
        }
      }
    }
  }
}
    `;

/**
 * __useEventForm_LocationsQuery__
 *
 * To run a query within a React component, call `useEventForm_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventForm_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventForm_LocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventForm_LocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventForm_LocationsQuery, EventForm_LocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventForm_LocationsQuery, EventForm_LocationsQueryVariables>(EventForm_LocationsDocument, baseOptions);
      }
export function useEventForm_LocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventForm_LocationsQuery, EventForm_LocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventForm_LocationsQuery, EventForm_LocationsQueryVariables>(EventForm_LocationsDocument, baseOptions);
        }
export type EventForm_LocationsQueryHookResult = ReturnType<typeof useEventForm_LocationsQuery>;
export type EventForm_LocationsLazyQueryHookResult = ReturnType<typeof useEventForm_LocationsLazyQuery>;
export type EventForm_LocationsQueryResult = ApolloReactCommon.QueryResult<EventForm_LocationsQuery, EventForm_LocationsQueryVariables>;
export const EventForm_UpsertEventDocument = gql`
    mutation EventForm_upsertEvent($id: String, $name: String!, $private: Boolean!, $bannerPhotoId: String, $thumbnailPhotoId: String, $locationId: String, $date: DateTime) {
  upsertEvent(
    id: $id
    name: $name
    bannerPhotoId: $bannerPhotoId
    thumbnailPhotoId: $thumbnailPhotoId
    date: $date
    locationId: $locationId
    private: $private
  ) {
    id
  }
}
    `;
export type EventForm_UpsertEventMutationFn = ApolloReactCommon.MutationFunction<EventForm_UpsertEventMutation, EventForm_UpsertEventMutationVariables>;

/**
 * __useEventForm_UpsertEventMutation__
 *
 * To run a mutation, you first call `useEventForm_UpsertEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_UpsertEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormUpsertEventMutation, { data, loading, error }] = useEventForm_UpsertEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      private: // value for 'private'
 *      bannerPhotoId: // value for 'bannerPhotoId'
 *      thumbnailPhotoId: // value for 'thumbnailPhotoId'
 *      locationId: // value for 'locationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useEventForm_UpsertEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_UpsertEventMutation, EventForm_UpsertEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_UpsertEventMutation, EventForm_UpsertEventMutationVariables>(EventForm_UpsertEventDocument, baseOptions);
      }
export type EventForm_UpsertEventMutationHookResult = ReturnType<typeof useEventForm_UpsertEventMutation>;
export type EventForm_UpsertEventMutationResult = ApolloReactCommon.MutationResult<EventForm_UpsertEventMutation>;
export type EventForm_UpsertEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_UpsertEventMutation, EventForm_UpsertEventMutationVariables>;
export const EventForm_UpdateEventDraftDocument = gql`
    mutation EventForm_updateEventDraft($id: String, $draft: Boolean) {
  upsertEvent(id: $id, draft: $draft) {
    id
  }
}
    `;
export type EventForm_UpdateEventDraftMutationFn = ApolloReactCommon.MutationFunction<EventForm_UpdateEventDraftMutation, EventForm_UpdateEventDraftMutationVariables>;

/**
 * __useEventForm_UpdateEventDraftMutation__
 *
 * To run a mutation, you first call `useEventForm_UpdateEventDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_UpdateEventDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormUpdateEventDraftMutation, { data, loading, error }] = useEventForm_UpdateEventDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      draft: // value for 'draft'
 *   },
 * });
 */
export function useEventForm_UpdateEventDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_UpdateEventDraftMutation, EventForm_UpdateEventDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_UpdateEventDraftMutation, EventForm_UpdateEventDraftMutationVariables>(EventForm_UpdateEventDraftDocument, baseOptions);
      }
export type EventForm_UpdateEventDraftMutationHookResult = ReturnType<typeof useEventForm_UpdateEventDraftMutation>;
export type EventForm_UpdateEventDraftMutationResult = ApolloReactCommon.MutationResult<EventForm_UpdateEventDraftMutation>;
export type EventForm_UpdateEventDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_UpdateEventDraftMutation, EventForm_UpdateEventDraftMutationVariables>;
export const EventForm_AddPhotoToEventDocument = gql`
    mutation EventForm_addPhotoToEvent($id: String!, $name: String!, $url: String!, $eventId: String!, $takenAt: String!) {
  addPhotoToEvent(
    id: $id
    name: $name
    url: $url
    eventId: $eventId
    takenAt: $takenAt
  ) {
    id
  }
}
    `;
export type EventForm_AddPhotoToEventMutationFn = ApolloReactCommon.MutationFunction<EventForm_AddPhotoToEventMutation, EventForm_AddPhotoToEventMutationVariables>;

/**
 * __useEventForm_AddPhotoToEventMutation__
 *
 * To run a mutation, you first call `useEventForm_AddPhotoToEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_AddPhotoToEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormAddPhotoToEventMutation, { data, loading, error }] = useEventForm_AddPhotoToEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      eventId: // value for 'eventId'
 *      takenAt: // value for 'takenAt'
 *   },
 * });
 */
export function useEventForm_AddPhotoToEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_AddPhotoToEventMutation, EventForm_AddPhotoToEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_AddPhotoToEventMutation, EventForm_AddPhotoToEventMutationVariables>(EventForm_AddPhotoToEventDocument, baseOptions);
      }
export type EventForm_AddPhotoToEventMutationHookResult = ReturnType<typeof useEventForm_AddPhotoToEventMutation>;
export type EventForm_AddPhotoToEventMutationResult = ApolloReactCommon.MutationResult<EventForm_AddPhotoToEventMutation>;
export type EventForm_AddPhotoToEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_AddPhotoToEventMutation, EventForm_AddPhotoToEventMutationVariables>;
export const EventForm_DeletePhotosFromEventDocument = gql`
    mutation EventForm_deletePhotosFromEvent($eventId: String!) {
  deletePhotosFromEvent(eventId: $eventId)
}
    `;
export type EventForm_DeletePhotosFromEventMutationFn = ApolloReactCommon.MutationFunction<EventForm_DeletePhotosFromEventMutation, EventForm_DeletePhotosFromEventMutationVariables>;

/**
 * __useEventForm_DeletePhotosFromEventMutation__
 *
 * To run a mutation, you first call `useEventForm_DeletePhotosFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_DeletePhotosFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormDeletePhotosFromEventMutation, { data, loading, error }] = useEventForm_DeletePhotosFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventForm_DeletePhotosFromEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_DeletePhotosFromEventMutation, EventForm_DeletePhotosFromEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_DeletePhotosFromEventMutation, EventForm_DeletePhotosFromEventMutationVariables>(EventForm_DeletePhotosFromEventDocument, baseOptions);
      }
export type EventForm_DeletePhotosFromEventMutationHookResult = ReturnType<typeof useEventForm_DeletePhotosFromEventMutation>;
export type EventForm_DeletePhotosFromEventMutationResult = ApolloReactCommon.MutationResult<EventForm_DeletePhotosFromEventMutation>;
export type EventForm_DeletePhotosFromEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_DeletePhotosFromEventMutation, EventForm_DeletePhotosFromEventMutationVariables>;
export const EventForm_DeletePhotoDocument = gql`
    mutation EventForm_deletePhoto($deletePhotoId: String!) {
  deletePhoto(id: $deletePhotoId) {
    id
    name
  }
}
    `;
export type EventForm_DeletePhotoMutationFn = ApolloReactCommon.MutationFunction<EventForm_DeletePhotoMutation, EventForm_DeletePhotoMutationVariables>;

/**
 * __useEventForm_DeletePhotoMutation__
 *
 * To run a mutation, you first call `useEventForm_DeletePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_DeletePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormDeletePhotoMutation, { data, loading, error }] = useEventForm_DeletePhotoMutation({
 *   variables: {
 *      deletePhotoId: // value for 'deletePhotoId'
 *   },
 * });
 */
export function useEventForm_DeletePhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_DeletePhotoMutation, EventForm_DeletePhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_DeletePhotoMutation, EventForm_DeletePhotoMutationVariables>(EventForm_DeletePhotoDocument, baseOptions);
      }
export type EventForm_DeletePhotoMutationHookResult = ReturnType<typeof useEventForm_DeletePhotoMutation>;
export type EventForm_DeletePhotoMutationResult = ApolloReactCommon.MutationResult<EventForm_DeletePhotoMutation>;
export type EventForm_DeletePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_DeletePhotoMutation, EventForm_DeletePhotoMutationVariables>;
export const EventForm_DeleteThumbnailPhotoFromEventDocument = gql`
    mutation EventForm_deleteThumbnailPhotoFromEvent($eventId: String!) {
  deleteThumbnailPhotoFromEvent(eventId: $eventId) {
    id
  }
}
    `;
export type EventForm_DeleteThumbnailPhotoFromEventMutationFn = ApolloReactCommon.MutationFunction<EventForm_DeleteThumbnailPhotoFromEventMutation, EventForm_DeleteThumbnailPhotoFromEventMutationVariables>;

/**
 * __useEventForm_DeleteThumbnailPhotoFromEventMutation__
 *
 * To run a mutation, you first call `useEventForm_DeleteThumbnailPhotoFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_DeleteThumbnailPhotoFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormDeleteThumbnailPhotoFromEventMutation, { data, loading, error }] = useEventForm_DeleteThumbnailPhotoFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventForm_DeleteThumbnailPhotoFromEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_DeleteThumbnailPhotoFromEventMutation, EventForm_DeleteThumbnailPhotoFromEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_DeleteThumbnailPhotoFromEventMutation, EventForm_DeleteThumbnailPhotoFromEventMutationVariables>(EventForm_DeleteThumbnailPhotoFromEventDocument, baseOptions);
      }
export type EventForm_DeleteThumbnailPhotoFromEventMutationHookResult = ReturnType<typeof useEventForm_DeleteThumbnailPhotoFromEventMutation>;
export type EventForm_DeleteThumbnailPhotoFromEventMutationResult = ApolloReactCommon.MutationResult<EventForm_DeleteThumbnailPhotoFromEventMutation>;
export type EventForm_DeleteThumbnailPhotoFromEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_DeleteThumbnailPhotoFromEventMutation, EventForm_DeleteThumbnailPhotoFromEventMutationVariables>;
export const EventForm_DeleteBannerPhotoFromEventDocument = gql`
    mutation EventForm_deleteBannerPhotoFromEvent($eventId: String!) {
  deleteBannerPhotoFromEvent(eventId: $eventId) {
    id
  }
}
    `;
export type EventForm_DeleteBannerPhotoFromEventMutationFn = ApolloReactCommon.MutationFunction<EventForm_DeleteBannerPhotoFromEventMutation, EventForm_DeleteBannerPhotoFromEventMutationVariables>;

/**
 * __useEventForm_DeleteBannerPhotoFromEventMutation__
 *
 * To run a mutation, you first call `useEventForm_DeleteBannerPhotoFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventForm_DeleteBannerPhotoFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventFormDeleteBannerPhotoFromEventMutation, { data, loading, error }] = useEventForm_DeleteBannerPhotoFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventForm_DeleteBannerPhotoFromEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EventForm_DeleteBannerPhotoFromEventMutation, EventForm_DeleteBannerPhotoFromEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EventForm_DeleteBannerPhotoFromEventMutation, EventForm_DeleteBannerPhotoFromEventMutationVariables>(EventForm_DeleteBannerPhotoFromEventDocument, baseOptions);
      }
export type EventForm_DeleteBannerPhotoFromEventMutationHookResult = ReturnType<typeof useEventForm_DeleteBannerPhotoFromEventMutation>;
export type EventForm_DeleteBannerPhotoFromEventMutationResult = ApolloReactCommon.MutationResult<EventForm_DeleteBannerPhotoFromEventMutation>;
export type EventForm_DeleteBannerPhotoFromEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EventForm_DeleteBannerPhotoFromEventMutation, EventForm_DeleteBannerPhotoFromEventMutationVariables>;
export const Events_EventsDocument = gql`
    query Events_events($skip: Int!, $take: Int!) {
  events(skip: $skip, take: $take, orderBy: {createdAt: desc}) {
    size
    list {
      id
      name
      draft
      date
      createdAt
    }
  }
}
    `;

/**
 * __useEvents_EventsQuery__
 *
 * To run a query within a React component, call `useEvents_EventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvents_EventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvents_EventsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useEvents_EventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Events_EventsQuery, Events_EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<Events_EventsQuery, Events_EventsQueryVariables>(Events_EventsDocument, baseOptions);
      }
export function useEvents_EventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Events_EventsQuery, Events_EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Events_EventsQuery, Events_EventsQueryVariables>(Events_EventsDocument, baseOptions);
        }
export type Events_EventsQueryHookResult = ReturnType<typeof useEvents_EventsQuery>;
export type Events_EventsLazyQueryHookResult = ReturnType<typeof useEvents_EventsLazyQuery>;
export type Events_EventsQueryResult = ApolloReactCommon.QueryResult<Events_EventsQuery, Events_EventsQueryVariables>;
export const Events_DeleteEventDocument = gql`
    mutation Events_deleteEvent($id: String!) {
  deleteEvent(id: $id)
}
    `;
export type Events_DeleteEventMutationFn = ApolloReactCommon.MutationFunction<Events_DeleteEventMutation, Events_DeleteEventMutationVariables>;

/**
 * __useEvents_DeleteEventMutation__
 *
 * To run a mutation, you first call `useEvents_DeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvents_DeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventsDeleteEventMutation, { data, loading, error }] = useEvents_DeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEvents_DeleteEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Events_DeleteEventMutation, Events_DeleteEventMutationVariables>) {
        return ApolloReactHooks.useMutation<Events_DeleteEventMutation, Events_DeleteEventMutationVariables>(Events_DeleteEventDocument, baseOptions);
      }
export type Events_DeleteEventMutationHookResult = ReturnType<typeof useEvents_DeleteEventMutation>;
export type Events_DeleteEventMutationResult = ApolloReactCommon.MutationResult<Events_DeleteEventMutation>;
export type Events_DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<Events_DeleteEventMutation, Events_DeleteEventMutationVariables>;
export const LocationForm_LocationDocument = gql`
    query LocationForm_location($id: String!) {
  location(id: $id) {
    id
    name
    City {
      id
    }
  }
}
    `;

/**
 * __useLocationForm_LocationQuery__
 *
 * To run a query within a React component, call `useLocationForm_LocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationForm_LocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationForm_LocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationForm_LocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationForm_LocationQuery, LocationForm_LocationQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationForm_LocationQuery, LocationForm_LocationQueryVariables>(LocationForm_LocationDocument, baseOptions);
      }
export function useLocationForm_LocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationForm_LocationQuery, LocationForm_LocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationForm_LocationQuery, LocationForm_LocationQueryVariables>(LocationForm_LocationDocument, baseOptions);
        }
export type LocationForm_LocationQueryHookResult = ReturnType<typeof useLocationForm_LocationQuery>;
export type LocationForm_LocationLazyQueryHookResult = ReturnType<typeof useLocationForm_LocationLazyQuery>;
export type LocationForm_LocationQueryResult = ApolloReactCommon.QueryResult<LocationForm_LocationQuery, LocationForm_LocationQueryVariables>;
export const LocationForm_CitiesDocument = gql`
    query LocationForm_cities {
  cities {
    id
    name
    State {
      id
      UF
    }
  }
}
    `;

/**
 * __useLocationForm_CitiesQuery__
 *
 * To run a query within a React component, call `useLocationForm_CitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationForm_CitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationForm_CitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationForm_CitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationForm_CitiesQuery, LocationForm_CitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationForm_CitiesQuery, LocationForm_CitiesQueryVariables>(LocationForm_CitiesDocument, baseOptions);
      }
export function useLocationForm_CitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationForm_CitiesQuery, LocationForm_CitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationForm_CitiesQuery, LocationForm_CitiesQueryVariables>(LocationForm_CitiesDocument, baseOptions);
        }
export type LocationForm_CitiesQueryHookResult = ReturnType<typeof useLocationForm_CitiesQuery>;
export type LocationForm_CitiesLazyQueryHookResult = ReturnType<typeof useLocationForm_CitiesLazyQuery>;
export type LocationForm_CitiesQueryResult = ApolloReactCommon.QueryResult<LocationForm_CitiesQuery, LocationForm_CitiesQueryVariables>;
export const LocationForm_UpsertLocationDocument = gql`
    mutation LocationForm_upsertLocation($id: String, $name: String!, $cityId: String!) {
  upsertLocation(id: $id, name: $name, cityId: $cityId) {
    id
  }
}
    `;
export type LocationForm_UpsertLocationMutationFn = ApolloReactCommon.MutationFunction<LocationForm_UpsertLocationMutation, LocationForm_UpsertLocationMutationVariables>;

/**
 * __useLocationForm_UpsertLocationMutation__
 *
 * To run a mutation, you first call `useLocationForm_UpsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationForm_UpsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationFormUpsertLocationMutation, { data, loading, error }] = useLocationForm_UpsertLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useLocationForm_UpsertLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LocationForm_UpsertLocationMutation, LocationForm_UpsertLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<LocationForm_UpsertLocationMutation, LocationForm_UpsertLocationMutationVariables>(LocationForm_UpsertLocationDocument, baseOptions);
      }
export type LocationForm_UpsertLocationMutationHookResult = ReturnType<typeof useLocationForm_UpsertLocationMutation>;
export type LocationForm_UpsertLocationMutationResult = ApolloReactCommon.MutationResult<LocationForm_UpsertLocationMutation>;
export type LocationForm_UpsertLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<LocationForm_UpsertLocationMutation, LocationForm_UpsertLocationMutationVariables>;
export const Locations_LocationsDocument = gql`
    query Locations_locations($skip: Int!, $take: Int!) {
  locations(skip: $skip, take: $take, orderBy: {createdAt: desc}) {
    size
    list {
      id
      name
      City {
        id
        name
        State {
          id
          UF
        }
      }
      createdAt
    }
  }
}
    `;

/**
 * __useLocations_LocationsQuery__
 *
 * To run a query within a React component, call `useLocations_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocations_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocations_LocationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useLocations_LocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Locations_LocationsQuery, Locations_LocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<Locations_LocationsQuery, Locations_LocationsQueryVariables>(Locations_LocationsDocument, baseOptions);
      }
export function useLocations_LocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Locations_LocationsQuery, Locations_LocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Locations_LocationsQuery, Locations_LocationsQueryVariables>(Locations_LocationsDocument, baseOptions);
        }
export type Locations_LocationsQueryHookResult = ReturnType<typeof useLocations_LocationsQuery>;
export type Locations_LocationsLazyQueryHookResult = ReturnType<typeof useLocations_LocationsLazyQuery>;
export type Locations_LocationsQueryResult = ApolloReactCommon.QueryResult<Locations_LocationsQuery, Locations_LocationsQueryVariables>;
export const Event_PhotosDocument = gql`
    query Event_photos($skip: Int!, $take: Int!, $eventId: String!) {
  photos(eventId: $eventId, skip: $skip, take: $take, orderBy: {name: asc}) {
    id
    size
    list {
      id
      name
      extension
      url
    }
  }
}
    `;

/**
 * __useEvent_PhotosQuery__
 *
 * To run a query within a React component, call `useEvent_PhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvent_PhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvent_PhotosQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEvent_PhotosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Event_PhotosQuery, Event_PhotosQueryVariables>) {
        return ApolloReactHooks.useQuery<Event_PhotosQuery, Event_PhotosQueryVariables>(Event_PhotosDocument, baseOptions);
      }
export function useEvent_PhotosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Event_PhotosQuery, Event_PhotosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Event_PhotosQuery, Event_PhotosQueryVariables>(Event_PhotosDocument, baseOptions);
        }
export type Event_PhotosQueryHookResult = ReturnType<typeof useEvent_PhotosQuery>;
export type Event_PhotosLazyQueryHookResult = ReturnType<typeof useEvent_PhotosLazyQuery>;
export type Event_PhotosQueryResult = ApolloReactCommon.QueryResult<Event_PhotosQuery, Event_PhotosQueryVariables>;
export const Event_EventDocument = gql`
    query Event_event($id: String!) {
  event(id: $id) {
    id
    name
    date
    Location {
      id
      name
      City {
        id
        name
        State {
          id
          UF
        }
      }
    }
    BannerPhoto {
      id
      name
      extension
      size
    }
  }
}
    `;

/**
 * __useEvent_EventQuery__
 *
 * To run a query within a React component, call `useEvent_EventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvent_EventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvent_EventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEvent_EventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Event_EventQuery, Event_EventQueryVariables>) {
        return ApolloReactHooks.useQuery<Event_EventQuery, Event_EventQueryVariables>(Event_EventDocument, baseOptions);
      }
export function useEvent_EventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Event_EventQuery, Event_EventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Event_EventQuery, Event_EventQueryVariables>(Event_EventDocument, baseOptions);
        }
export type Event_EventQueryHookResult = ReturnType<typeof useEvent_EventQuery>;
export type Event_EventLazyQueryHookResult = ReturnType<typeof useEvent_EventLazyQuery>;
export type Event_EventQueryResult = ApolloReactCommon.QueryResult<Event_EventQuery, Event_EventQueryVariables>;
export const Home_EventsDocument = gql`
    query Home_events($skip: Int!, $take: Int!, $draft: Boolean) {
  events(skip: $skip, take: $take, draft: $draft, orderBy: {date: desc}) {
    size
    list {
      id
      name
      date
      Location {
        id
        name
        City {
          id
          name
          State {
            id
            UF
          }
        }
      }
      ThumbnailPhoto {
        id
        name
        extension
        url
      }
      BannerPhoto {
        id
        name
        extension
        url
      }
    }
  }
}
    `;

/**
 * __useHome_EventsQuery__
 *
 * To run a query within a React component, call `useHome_EventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHome_EventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHome_EventsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      draft: // value for 'draft'
 *   },
 * });
 */
export function useHome_EventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Home_EventsQuery, Home_EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<Home_EventsQuery, Home_EventsQueryVariables>(Home_EventsDocument, baseOptions);
      }
export function useHome_EventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Home_EventsQuery, Home_EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Home_EventsQuery, Home_EventsQueryVariables>(Home_EventsDocument, baseOptions);
        }
export type Home_EventsQueryHookResult = ReturnType<typeof useHome_EventsQuery>;
export type Home_EventsLazyQueryHookResult = ReturnType<typeof useHome_EventsLazyQuery>;
export type Home_EventsQueryResult = ApolloReactCommon.QueryResult<Home_EventsQuery, Home_EventsQueryVariables>;
export const Home_ConfigDocument = gql`
    query Home_config {
  config {
    BannerEvent {
      id
      name
      date
      Location {
        id
        name
        City {
          id
          name
          State {
            id
            UF
          }
        }
      }
      BannerPhoto {
        id
        name
        extension
        url
      }
    }
  }
}
    `;

/**
 * __useHome_ConfigQuery__
 *
 * To run a query within a React component, call `useHome_ConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useHome_ConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHome_ConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useHome_ConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Home_ConfigQuery, Home_ConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<Home_ConfigQuery, Home_ConfigQueryVariables>(Home_ConfigDocument, baseOptions);
      }
export function useHome_ConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Home_ConfigQuery, Home_ConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Home_ConfigQuery, Home_ConfigQueryVariables>(Home_ConfigDocument, baseOptions);
        }
export type Home_ConfigQueryHookResult = ReturnType<typeof useHome_ConfigQuery>;
export type Home_ConfigLazyQueryHookResult = ReturnType<typeof useHome_ConfigLazyQuery>;
export type Home_ConfigQueryResult = ApolloReactCommon.QueryResult<Home_ConfigQuery, Home_ConfigQueryVariables>;