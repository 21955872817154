import { IconButton, Badge } from "@mui/material";
import CartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../context/UserContext";

const CartButton: React.FC = () => {
  const { cartItems } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Badge badgeContent={cartItems.length} color="primary">
      <IconButton onClick={() => navigate("/cart")}>
        <CartIcon />
      </IconButton>
    </Badge>
  );
};

export default CartButton;
