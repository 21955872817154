import {
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { Spacer } from "../../../components/Spacer";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EventForm_EventQueryResult,
  EventForm_LocationsQueryResult,
  useEventForm_UpsertEventMutation,
} from "../../../generated/graphql";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { getOption } from "../../../components/util/autocomplete";

const ButtonsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const CForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: 32,
});

const CFormControlLabel = styled(FormControlLabel)({
  color: "white",
  width: 350,
  marginBottom: 18,
});

const NextButton = styled(Button)({
  alignSelf: "flex-end",
});

const CTextField = styled(TextField)({
  width: 350,
  marginBottom: 18,
});

export interface IFormInputs {
  name?: string;
  date?: Date;
  locationId: string;
  private?: boolean;
}

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .min(4, "Dever ter no mínimo 4 carácteres")
      .max(24, "Deve ter no máximo 24 carácteres")
      .required("Obrigatório"),
    date: yup.date().required("Obrigatório"),
    private: yup.boolean().required("Obrigatório"),
    locationId: yup.string().required("Obrigatório"),
  })
  .required();

type BaseDataStepProps = {
  eventId?: string;
  onEventId: (s: string) => void;
  defaultValues?: Partial<IFormInputs>;
  onNext: () => void;
  onLoading: (b: boolean) => void;
  loading: boolean;
  Locations: Exclude<
    EventForm_LocationsQueryResult["data"],
    undefined
  >["locations"]["list"];
};

export const BaseDataStep: React.FC<BaseDataStepProps> = ({
  Locations,
  eventId,
  defaultValues,
  onNext,
  onLoading,
  onEventId,
  loading,
}) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      ...defaultValues,
      private: defaultValues?.private ?? false,
      date: defaultValues?.date ? new Date(defaultValues.date) : new Date(),
    },
    resolver: yupResolver(schema),
  });
  const [upsertEvent] = useEventForm_UpsertEventMutation();

  const onSubmit = async (data: IFormInputs) => {
    onLoading(true);
    const res = await upsertEvent({
      variables: {
        id: eventId,
        name: data.name || "",
        date: data.date,
        locationId: data.locationId,
        private: data.private ?? false,
      },
    });
    if (!eventId && res.data) {
      onEventId(res.data?.upsertEvent.id);
    }
    onLoading(false);

    onNext();
  };
  const LocationsWithInitials = Locations.map((i) => ({
    id: i.id,
    name: `${i.name} - ${i.City?.name || ""} - ${i.City?.State.UF || ""}`,
  }));
  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="private"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CFormControlLabel
            control={<Switch checked={value} onChange={onChange} />}
            label="Privado"
          />
        )}
      />
      <CTextField
        label="Nome"
        inputProps={register("name")}
        error={!!errors.name}
        helperText={errors.name?.message}
        disabled={loading}
      />
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, value } }) => (
          <MobileDatePicker
            label="Data"
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <CTextField
                {...params}
                error={!!errors.date}
                helperText={errors.date?.message}
              />
            )}
          />
        )}
      />

      <Autocomplete
        disablePortal
        onChange={(e, value) => {
          value && setValue("locationId", value.id);
        }}
        options={LocationsWithInitials}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <CTextField
            {...params}
            label="Local"
            error={!!errors.locationId}
            helperText={errors.locationId?.message}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        defaultValue={getOption(
          LocationsWithInitials,
          defaultValues?.locationId
        )}
      />
      <ButtonsContainer>
        <Spacer />
        <NextButton type="submit">Próximo</NextButton>
      </ButtonsContainer>
    </CForm>
  );
};
