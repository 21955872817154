import { styled } from "@mui/system";
import { format } from "date-fns";

const EventContainer = styled("div")({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
});

const EventImage = styled("img")({
  width: 300,
  height: 200,
  objectFit: "cover",
  borderRadius: 6,
  marginBottom: 18,
});

const EventDetailsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 18,
});

const EventNameText = styled("a")({
  fontWeight: "bold",
  color: "white",
  fontSize: 28,
});

const EventLocationContainer = styled("div")({
  fontWeight: "bold",
  color: "white",
  fontSize: 12,
  marginBottom: 6,
});

const EventLocationLabelText = styled("a")({
  fontWeight: "lighter",
  color: "lightgray",
});

const EventCreatedDateText = styled("a")({
  color: "white",
  fontSize: 12,
  fontWeight: "lighter",
});

type EventMiniature = {
  image: string;
  name: string;
  location: string;
  date: string;
  onClick: () => void;
};

export const EventMiniature = ({ image, name, location, date, onClick }) => {
  return (
    <EventContainer onClick={onClick}>
      <EventImage src={image} alt={name} />
      <EventDetailsContainer>
        <EventNameText>{name}</EventNameText>
        <EventLocationContainer>
          <EventLocationLabelText>Local: </EventLocationLabelText>
          <a>{location}</a>
        </EventLocationContainer>
        <EventCreatedDateText>Postado Em {date}</EventCreatedDateText>
      </EventDetailsContainer>
    </EventContainer>
  );
};
