import React, { useContext } from "react";
import { Appbar } from "../components/Appbar";
import { Logo } from "../components/Logo";
import UserContext from "../context/UserContext";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const MainPane = styled("div")({
  margin: 12,
  display: "flex",
  flexDirection: "column",
});

const MenuButton = styled(Button)({
  color: "white",
});

export const AdminFrame: React.FC = () => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorElRecords, setAnchorElRecords] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRecords(event.currentTarget);
  };

  const handleCloseRecordMenu = () => {
    setAnchorElRecords(null);
  };
  return (
    <>
      <Appbar>
        <Logo height={65} onClick={() => navigate("/admin/events")} />
        <MenuButton onClick={handleOpenUserMenu}>Cadastros</MenuButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElRecords}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElRecords)}
          onClose={handleCloseRecordMenu}
        >
          <MenuItem onClick={handleCloseRecordMenu}>
            <Typography
              textAlign="center"
              onClick={() => navigate("/admin/locations")}
            >
              Locations
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleCloseRecordMenu}>
            <Typography
              textAlign="center"
              onClick={() => navigate("/admin/events")}
            >
              Eventos
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleCloseRecordMenu}>
            <Typography
              textAlign="center"
              onClick={() => navigate("/admin/config")}
            >
              Config
            </Typography>
          </MenuItem>
        </Menu>
        <div style={{ flex: 1 }} />
        <Button
          style={{ margin: 12 }}
          onClick={async () => {
            await logout();
            navigate("/");
          }}
        >
          Sair
        </Button>
      </Appbar>
      <MainPane>
        <Outlet />
      </MainPane>
    </>
  );
};
