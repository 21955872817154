import {
  Box,
  LinearProgress,
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  LocationForm_LocationQuery,
  useLocationForm_LocationQuery,
  useLocationForm_UpsertLocationMutation,
  useLocationForm_CitiesQuery,
  LocationForm_CitiesQuery,
} from "../../../generated/graphql";

import { styled } from "@mui/system";
import { Spacer } from "../../../components/Spacer";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventForm_UpsertEventMutation } from "../../../generated/graphql";
import { MobileDatePicker } from "@mui/lab";
import { getOption } from "../../../components/util/autocomplete";

const ButtonsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const CForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: 32,
});

const NextButton = styled(Button)({
  alignSelf: "flex-end",
});

const CTextField = styled(TextField)({
  width: 350,
  marginBottom: 18,
});

export const LocationForm = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data, loading } = useLocationForm_LocationQuery({
    variables: { id: locationId || "" },
    skip: !locationId,
  });
  const { data: cities, loading: citiesLoading } =
    useLocationForm_CitiesQuery();
  if (loading || citiesLoading) {
    return <LinearProgress />;
  }

  return (
    <Form
      locationId={locationId}
      defaultValues={{ ...data?.location, cityId: data?.location?.City?.id }}
      Cities={cities?.cities || []}
    />
  );
};

export interface IFormInputs {
  name?: string;
  cityId: string;
}

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .min(3, "Dever ter no mínimo 3 carácteres")
      .max(24, "Deve ter no máximo 24 carácteres")
      .required("Obrigatório"),
    cityId: yup.string().required("Obrigatório"),
  })
  .required();

export type EventFormFormProps = {
  locationId?: string;
  defaultValues?: Partial<IFormInputs>;
  Cities: Exclude<LocationForm_CitiesQuery["cities"], undefined>;
};

export const Form: React.FC<EventFormFormProps> = ({
  locationId,
  defaultValues,
  Cities,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const [upsertLocation, { loading }] =
    useLocationForm_UpsertLocationMutation();

  const onSubmit = async (data: IFormInputs) => {
    await upsertLocation({
      variables: {
        id: locationId,
        name: data.name || "",
        cityId: data.cityId,
      },
    });
    navigate("/admin/locations");
  };
  const CitiesWithInitials = Cities.map((i) => ({
    id: i.id,
    name: `${i.name} - ${i.State.UF}`,
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <CForm onSubmit={handleSubmit(onSubmit)}>
        <CTextField
          label="Nome"
          inputProps={register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
          disabled={loading}
        />
        <Autocomplete
          disablePortal
          onChange={(e, value) => {
            value && setValue("cityId", value.id);
          }}
          options={CitiesWithInitials}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <CTextField
              {...params}
              label="Cidade"
              error={!!errors.cityId}
              helperText={errors.cityId?.message}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          defaultValue={getOption(CitiesWithInitials, defaultValues?.cityId)}
        />

        <ButtonsContainer>
          <Spacer />
          <NextButton type="submit">Próximo</NextButton>
        </ButtonsContainer>
      </CForm>
    </Box>
  );
};
