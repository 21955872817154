import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

const LoadMoreContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "64px 0",
});

const LoadMoreText = styled("div")({
  backgroundColor: "white",
  padding: "12px 64px",
  color: "black",
  borderRadius: 18,
  fontWeight: "bold",
  cursor: "pointer",
  userSelect: "none",
});

type LoadMoreButtonProps = {
  loading: boolean;
  onClick: () => void;
};

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  loading,
  onClick,
}) => {
  return (
    <LoadMoreContainer>
      {!loading ? (
        <LoadMoreText onClick={onClick}>Carregar mais</LoadMoreText>
      ) : (
        <CircularProgress style={{ color: "white" }} size={32} />
      )}
    </LoadMoreContainer>
  );
};
