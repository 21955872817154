import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Appbar } from "../../components/Appbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/system";
import { useLogin_LoginMutation } from "../../generated/graphql";
import UserContext from "../../context/UserContext";

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 128,
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 80px)",
});

const LoginTextField = styled(TextField)({
  width: 450,
  margin: 12,
  cursor: "pointer",
});

const LoginButton = styled(Button)({
  width: 450,
  margin: 12,
  cursor: "pointer",
});

const ForgotPasswordText = styled(Typography)({
  width: 450,
  margin: 12,
  cursor: "pointer",
  color: "white",
});

const RememberLabel = styled(FormControlLabel)({
  width: 450,
  marginLeft: 4,
  color: "white",
});

const ErrorText = styled(Typography)({
  color: "red",
  width: "100%",
  textAlign: "center",
});

export const Login = () => {
  const [login] = useLogin_LoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemeber] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setSession } = useContext(UserContext);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
      setRemeber(true);
    }
  }, []);

  return (
    <div>
      <Appbar></Appbar>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            const data = await login({
              variables: {
                email,
                password,
              },
            });
            if (data.data?.login.token) {
              setSession(data.data.login);
              navigate("/admin/events");
            } else {
              setError("Algo inesperado aconteceu");
            }

            if (remember) {
              localStorage.setItem("email", email);
            } else {
              localStorage.removeItem("email");
            }
          } catch (error: any) {
            console.log("An unexpected error happened:", error);
            setError("Usuário ou senha incorreto.");
          }
        }}
      >
        <ErrorText>{error}</ErrorText>
        <LoginTextField
          label="Email"
          type="text"
          variant="outlined"
          inputProps={{
            autoCapitalize: "none",
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <LoginTextField
          label="Senha"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RememberLabel
          checked={remember}
          onClick={() => setRemeber(!remember)}
          control={<Checkbox value="remember" color="primary" />}
          label="Lembrar"
        />
        <LoginButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={false}
        >
          {!false ? "Entrar" : <CircularProgress size={24} color="secondary" />}
        </LoginButton>
        <ForgotPasswordText variant="body2" onClick={() => {}}>
          Esqueci minha senha
        </ForgotPasswordText>
      </Form>
    </div>
  );
};
