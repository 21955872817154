import CartAddIcon from "@mui/icons-material/AddShoppingCart";
import CartRemoveIcon from "@mui/icons-material/RemoveShoppingCart";
import { IconButton } from "@mui/material";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import { CartItem } from "../hooks/useCart";

type PhotoCartButtonProps = {} & CartItem;

const PhotoCartButton: React.FC<PhotoCartButtonProps> = ({
  id,
  name,
  src,
  eventName,
  eventLocation,
  eventId,
  eventDate,
}) => {
  const { cartItems, setCartItems } = useContext(UserContext);
  if (cartItems.filter((i) => i.name === name)?.[0]) {
    return (
      <IconButton
        onClick={() => setCartItems(cartItems.filter((i) => i.id !== id))}
      >
        <CartRemoveIcon style={{ color: "red" }} />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        onClick={() =>
          setCartItems([
            ...cartItems,
            { id, name, src, eventName, eventLocation, eventId, eventDate },
          ])
        }
      >
        <CartAddIcon style={{ color: "green" }} />
      </IconButton>
    );
  }
};

export default PhotoCartButton;
