import { Button } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { DataColumnConfig, DataTable } from "../../../components/DataTable";
import { useLocations_LocationsQuery } from "../../../generated/graphql";
import { PAGINATION_TAKE, usePagination } from "../../../util/pagination";

const columns: DataColumnConfig[] = [
  { field: "name", headerName: "Nome", width: 130 },
  { field: "city", headerName: "Cidade", width: 130 },
  { field: "createdAt", headerName: "Criado em", width: 130 },
];

export const AdminLocations = () => {
  const navigate = useNavigate();
  const { data, refetch } = useLocations_LocationsQuery({
    variables: { skip: 0, take: PAGINATION_TAKE },
  });
  const pagination = usePagination({
    size: data?.locations.size || 0,
    get: (skip, take) => refetch({ skip, take }),
  });
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", margin: 12 }}>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/admin/locations/create");
          }}
        >
          Adicionar
        </Button>
      </div>
      <div style={{ height: 500 }}>
        <DataTable
          rows={
            data?.locations.list.map((i) => ({
              ...i,
              city: `${i.City?.name || ""} ${i.City?.State.UF}`,
              createdAt: format(i.createdAt, "dd/MM/yyyy"),
            })) || []
          }
          columns={columns}
          onRowClick={(row) => navigate(`/admin/locations/${row.id}/edit`)}
          {...pagination}
        />
      </div>
    </>
  );
};
