import { Box, LinearProgress, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  EventForm_EventQueryResult,
  EventForm_LocationsQueryResult,
  useEventForm_EventQuery,
  useEventForm_LocationsQuery,
} from "../../../generated/graphql";
import { BaseDataStep, IFormInputs } from "./BaseDataStep";
import { UploadStep } from "./UploadStep";

const steps = ["Dados", "Upload de fotos"];

export const EventForm = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event, loading } = useEventForm_EventQuery({
    variables: { id: eventId || "" },
    skip: !eventId,
  });
  const { data: locations, loading: locationsLoading } =
    useEventForm_LocationsQuery();

  if (loading || locationsLoading) {
    return <LinearProgress />;
  }

  return (
    <Form
      eventId={eventId}
      defaultValues={{ ...event?.event, locationId: event?.event.Location?.id }}
      Photos={event?.event.Photos || []}
      BannerPhoto={event?.event.BannerPhoto}
      ThumbnailPhoto={event?.event.ThumbnailPhoto}
      Locations={locations?.locations.list || []}
    />
  );
};

export type EventFormFormProps = {
  eventId?: string;
  defaultValues?: Partial<IFormInputs>;
  Photos: Exclude<
    Exclude<EventForm_EventQueryResult["data"], undefined>["event"],
    undefined
  >["Photos"];
  BannerPhoto?: Exclude<
    Exclude<EventForm_EventQueryResult["data"], undefined>["event"],
    undefined
  >["BannerPhoto"];
  ThumbnailPhoto?: Exclude<
    Exclude<EventForm_EventQueryResult["data"], undefined>["event"],
    undefined
  >["ThumbnailPhoto"];
  Locations: Exclude<
    EventForm_LocationsQueryResult["data"],
    undefined
  >["locations"]["list"];
};

export const Form: React.FC<EventFormFormProps> = ({
  eventId: defaultEventId,
  defaultValues,
  Photos,
  BannerPhoto,
  ThumbnailPhoto,
  Locations,
}) => {
  const [step, setStep] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [eventId, setEventId] = useState(defaultEventId);

  return (
    <Box sx={{ width: "100%" }}>
      {uploadLoading ? <LinearProgress /> : null}
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step === 0 && (
        <BaseDataStep
          Locations={Locations}
          eventId={eventId}
          defaultValues={defaultValues}
          onNext={() => setStep(1)}
          onLoading={(b) => setUploadLoading(b)}
          onEventId={(e) => setEventId(e)}
          loading={uploadLoading}
        />
      )}
      {step === 1 && eventId && (
        <UploadStep
          eventId={eventId}
          Photos={Photos}
          onLoading={(b) => setUploadLoading(b)}
          BannerPhoto={BannerPhoto}
          ThumbnailPhoto={ThumbnailPhoto}
        />
      )}
    </Box>
  );
};
