import useLocalStorage from "./useLocalStorage";

export type CartItem = {
  id: string;
  name: string;
  eventName: string;
  eventLocation: string;
  eventDate: string;
  eventId: string;
  src: string;
};

export default function useCart() {
  return useLocalStorage("cartItems", [] as CartItem[]);
}
