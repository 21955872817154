import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/main/Home";
import { AdminEvents } from "./pages/admin/Events";
import { Login } from "./pages/Login";
import { useContext } from "react";
import UserContext from "./context/UserContext";
import { EventForm } from "./pages/admin/EventForm";
import { ConfigForm } from "./pages/admin/ConfigForm";
import { AdminFrame } from "./smartComponents/AdminFrame";
import { AdminLocations } from "./pages/admin/Locations";
import { LocationForm } from "./pages/admin/LocationForm";
import { Event } from "./pages/main/Event";
import { Cart } from "./pages/main/Cart";

const RequireAdminAuth: React.FC = ({ children }) => {
  const { session, loading } = useContext(UserContext);
  let location = useLocation();
  if (loading) {
    return <div>Carregando...</div>;
  } else if (!session?.User?.admin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/event/:eventId" element={<Event />} />
      <Route path="/cart" element={<Cart />} />
      <Route
        path="/admin"
        element={
          <RequireAdminAuth>
            <AdminFrame />
          </RequireAdminAuth>
        }
      >
        <Route path="/admin" element={<AdminEvents />} />
        <Route path="/admin/config" element={<ConfigForm />} />

        <Route path="/admin/events" element={<AdminEvents />} />
        <Route path="/admin/events/create" element={<EventForm />} />
        <Route path="/admin/events/:eventId/edit" element={<EventForm />} />

        <Route path="/admin/locations" element={<AdminLocations />} />
        <Route path="/admin/locations/create" element={<LocationForm />} />
        <Route
          path="/admin/locations/:locationId/edit"
          element={<LocationForm />}
        />
      </Route>
    </Routes>
  );
}

export default App;
