import { styled } from "@mui/system";
import { format } from "date-fns";
import {
  useEvent_EventQuery,
  useEvent_PhotosQuery,
} from "../../../generated/graphql";
import { Banner } from "../../../components/Banner";
import { LoadMoreButton } from "../../../components/LoadMoreButton";
import { useNavigate, useParams } from "react-router-dom";
import { Photo } from "../../../components/Photo";
import { useState } from "react";
import GalleryDialog from "../../../components/GalleryDialog";
import PhotoCartButton from "../../../smartComponents/PhotoCartButton";
import PhotosGrid from "../../../components/PhotosGrid";
import CartButton from "../../../smartComponents/CartButton";
import { ptBR } from "date-fns/locale";
import { CircularProgress } from "@mui/material";
import { NetworkStatus } from "@apollo/client";

const EmptyText = styled("div")({
  width: "100%",
  padding: "48px 0",
  textAlign: "center",
  color: "white",
});

export const Event = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const {
    data: photos,
    networkStatus,
    fetchMore,
  } = useEvent_PhotosQuery({
    variables: {
      take: 100,
      skip: 0,
      eventId: eventId || "",
    },
    notifyOnNetworkStatusChange: true,
  });
  const { data: event } = useEvent_EventQuery({
    variables: {
      id: eventId || "",
    },
  });
  const [previewPhoto, setPreviewPhoto] = useState<number | undefined>();
  const navigate = useNavigate();
  const fetchMoreOnGallery = (photoIndex: number) => {
    if (photos && photoIndex > photos.photos.list.length - 5) {
      fetchMore({
        variables: {
          skip: photos?.photos.list.length,
        },
      });
    }
  };

  if (networkStatus === NetworkStatus.loading) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ color: "white", marginTop: 48 }} />
      </div>
    );
  }

  return (
    <div>
      {event ? (
        <Banner
          image={`${process.env.REACT_APP_S3_URL}/photos/${event?.event?.BannerPhoto?.id}.${event?.event?.BannerPhoto?.extension}`}
          name={event?.event?.name || ""}
          location={`${event?.event?.Location?.name || ""} - ${
            event?.event?.Location?.City?.name || ""
          }/${event?.event?.Location?.City?.State.UF || ""}`}
          date={format(
            event?.event?.date ? new Date(event?.event?.date) : new Date(),
            "PPP"
          )}
        />
      ) : null}
      <br />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }} />
        <div style={{ padding: "32px 5vw" }}>
          <CartButton />
        </div>
      </div>
      {photos?.photos.size ? (
        <>
          <PhotosGrid>
            {photos?.photos?.list.map((i, ind) => (
              <Photo
                key={i.id}
                image={`${process.env.REACT_APP_S3_URL}/photos/${i?.id}.${i?.extension}`}
                onClick={() => setPreviewPhoto(ind)}
              />
            ))}
          </PhotosGrid>
          {photos?.photos.size !== photos?.photos.list.length ? (
            <LoadMoreButton
              loading={NetworkStatus.fetchMore === networkStatus}
              onClick={async () => {
                await fetchMore({
                  variables: {
                    skip: photos?.photos.list.length,
                  },
                });
              }}
            />
          ) : undefined}
        </>
      ) : (
        <EmptyText>Nenhuma foto registrada</EmptyText>
      )}
      {previewPhoto !== undefined ? (
        <GalleryDialog
          open={previewPhoto !== undefined}
          onDismiss={() => {
            setPreviewPhoto(undefined);
          }}
          onGoToCartClick={() => navigate("/cart")}
          images={
            photos?.photos.list.map((i) => ({
              url: `${process.env.REACT_APP_S3_URL}/photos/${i?.id}.${i?.extension}`,
              name: i.name,
            })) || []
          }
          startIndex={previewPhoto}
          onOpen={(ind) => {
            fetchMoreOnGallery(ind);
          }}
          onIndexChanged={(ind) => {
            fetchMoreOnGallery(ind);
            setPreviewPhoto(ind);
          }}
          RightHeaderIcons={
            <PhotoCartButton
              id={photos?.photos.list[previewPhoto].id || ""}
              name={photos?.photos.list[previewPhoto].name || ""}
              src={`${process.env.REACT_APP_S3_URL}/photos/${photos?.photos.list[previewPhoto].id}.${photos?.photos.list[previewPhoto].extension}`}
              eventLocation={event?.event.Location?.name || ""}
              eventName={event?.event.name || ""}
              eventId={event?.event.id || ""}
              eventDate={
                format(new Date(event?.event.date), "dd/MM/yyyy", {
                  locale: ptBR,
                }) || ""
              }
            />
          }
        />
      ) : null}
    </div>
  );
};
