import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ImageGallery from "react-image-gallery";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";

const CAppBar = styled(AppBar)({
  position: "absolute",
});

const TitleText = styled(Typography)({
  color: "white",
  fontWeight: "bold",
  flex: 1,
  "@media (max-width: 650px)": {
    padding: "4px 8px",
    fontSize: 12,
  },
});
const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 32,
});

const ButtonText = styled("div")({
  backgroundColor: "white",
  padding: "12px 32px",
  color: "black",
  borderRadius: 8,
  fontWeight: "bold",
  cursor: "pointer",
  userSelect: "none",
  "@media (max-width: 650px)": {
    padding: "4px 8px",
    fontSize: 12,
  },
});

const GalleryWrapper = styled("div")({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 64,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type GalleryDialogProps = {
  open: boolean;
  onDismiss: () => void;
  onGoToCartClick?: () => void;
  RightHeaderIcons?: React.ReactElement;
  images: { name: string; url?: string }[];
  startIndex: number;
  onIndexChanged?: (i: number) => void;
  onOpen?: (i: number) => void;
};

const GalleryDialog: React.FC<GalleryDialogProps> = ({
  open,
  onDismiss,
  images,
  startIndex,
  RightHeaderIcons,
  onIndexChanged,
  onGoToCartClick,
  onOpen,
}) => {
  const [ind, setInd] = useState(startIndex || 0);

  useEffect(() => {
    onOpen && onOpen(startIndex);
  }, []);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onDismiss}
      TransitionComponent={Transition}
    >
      <CAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            // color="inherit"
            onClick={onDismiss}
          >
            <Close />
          </IconButton>
          <TitleText>{images?.[ind]?.name}</TitleText>
          {onGoToCartClick ? (
            <ButtonContainer onClick={onGoToCartClick}>
              <ButtonText>Ir para carrinho</ButtonText>
            </ButtonContainer>
          ) : undefined}
          {RightHeaderIcons}
        </Toolbar>
      </CAppBar>
      <GalleryWrapper>
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          startIndex={startIndex}
          items={images.map((i) => ({
            original: i.url,
            originalTitle: i.name,
          }))}
          onSlide={(ind) => {
            onIndexChanged && onIndexChanged(ind);
            setInd(ind);
          }}
        />
      </GalleryWrapper>
    </Dialog>
  );
};

export default GalleryDialog;
