import { useCallback, useState } from "react";

type UsePaginationArgs = {
  size: number;
  get: (skip: number, take: number) => void;
};

// import { useCallback, useState } from "react";

export const PAGINATION_TAKE = 25;

export const usePagination = ({ size, get }: UsePaginationArgs) => {
  const [skip, setSkip] = useState(0);

  // const onPageSizeChange = useCallback((newPageSize: number) => {
  //   setTake(newPageSize);
  // }, []);

  const onPageChange = useCallback(
    (newPage: number) => {
      setSkip(newPage * PAGINATION_TAKE);
      get(newPage * PAGINATION_TAKE, PAGINATION_TAKE);
    },
    [setSkip, get]
  );

  return {
    skip,
    onPageChange,
    size,
    // dataGridProps: {
    //   pageSize: take,
    //   onPageSizeChange,
    //   pagination: true as true,
    //   autoHeight: true as true,
    //   rowsPerPageOptions: [PAGINATION_TAKE],
    //   rowCount: size,
    //   onPageChange,
    //   disableSelectionOnClick: true as true,
    // },
  };
};

// export const usePagination = () => {
//   const [skip, setSkip] = useState(0);

//   const prev = useCallback(() => {
//     if (skip - TAKE >= 0) setSkip(skip - TAKE);
//   }, [skip]);

//   const next = useCallback(() => {
//     setSkip(skip + TAKE);
//   }, [skip]);

//   return {
//     skip,
//     prev,
//     next,
//     take: TAKE,
//   };
// };
