import { styled } from "@mui/system";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import {
  Home_EventsQuery,
  useHome_ConfigQuery,
  useHome_EventsQuery,
} from "../../../generated/graphql";
import CartButton from "../../../smartComponents/CartButton";
import { Banner } from "../../../components/Banner";
import { EventMiniature } from "../../../components/EventMiniature";
import { LoadMoreButton } from "../../../components/LoadMoreButton";
import PhotosGrid from "../../../components/PhotosGrid";
import { useNavigate } from "react-router-dom";

const FilterToolbar = styled("div")({
  display: "flex",
  flexDirection: "row",
  margin: "22px 15vw 12px 15vw",
});

const Spacer = styled("div")({
  flex: 1,
});

export const Home = () => {
  const navigate = useNavigate();
  const { data, loading, fetchMore } = useHome_EventsQuery({
    variables: {
      skip: 0,
      take: 100,
      draft: false,
    },
    notifyOnNetworkStatusChange: true,
  });
  let banner: Home_EventsQuery["events"]["list"][0] | undefined;
  if (banner === undefined && data && data.events.list.length > 0) {
    let n = getRandomInt(0, data.events.list.length - 1);
    banner = data.events.list[n];
  }

  return (
    <div>
      {banner ? (
        <Banner
          image={`${process.env.REACT_APP_S3_URL}/photos/${banner?.BannerPhoto?.id}.${banner?.BannerPhoto?.extension}`}
          name={banner?.name || ""}
          location={`${banner?.Location?.name || ""} - ${
            banner?.Location?.City?.name || ""
          }/${banner?.Location?.City?.State.UF || ""}`}
          date={format(
            banner?.date ? new Date(banner?.date) : new Date(),
            "PPP",
            { locale: ptBR }
          )}
          onClick={() => navigate(`/event/${banner?.id}`)}
        />
      ) : null}

      <FilterToolbar>
        <Spacer />
        <CartButton />
      </FilterToolbar>
      <br />
      <PhotosGrid>
        {data?.events.list.map((i) => (
          <EventMiniature
            key={i.id}
            image={`${process.env.REACT_APP_S3_URL}/photos/${i.ThumbnailPhoto?.id}.${i.ThumbnailPhoto?.extension}`}
            name={i.name}
            location={
              i?.Location
                ? `${i?.Location?.name || ""} - ${
                    i?.Location?.City?.name || ""
                  }/${i?.Location?.City?.State.UF || ""}`
                : " - "
            }
            date={format(i?.date ? new Date(i?.date) : new Date(), "PPP", {
              locale: ptBR,
            })}
            onClick={() => navigate(`/event/${i.id}`)}
          />
        ))}
      </PhotosGrid>
      {data?.events.list.length !== data?.events.size ? (
        <LoadMoreButton
          loading={loading}
          onClick={() =>
            fetchMore({
              variables: {
                skip: data?.events.list.length,
              },
            })
          }
        />
      ) : undefined}
    </div>
  );
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
