import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataColumnConfig, DataTable } from "../../../components/DataTable";
import {
  Events_EventsQuery,
  useEvents_DeleteEventMutation,
  useEvents_EventsQuery,
} from "../../../generated/graphql";
import { PAGINATION_TAKE, usePagination } from "../../../util/pagination";

const columns: DataColumnConfig[] = [
  // { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Nome" },
  { field: "draft", headerName: "Publicado" },
  { field: "date", headerName: "Data" },
  { field: "createdAt", headerName: "Criado em" },
  // { field: "lastName", headerName: "Last name"},
  // {
  //   field: "age",
  //   headerName: "Age",
  //   type: "number",
  //   width: 90,
  // },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params: GridValueGetterParams) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];

export const AdminEvents = () => {
  const navigate = useNavigate();
  const { data, refetch } = useEvents_EventsQuery({
    variables: { skip: 0, take: PAGINATION_TAKE },
  });
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteEvent] = useEvents_DeleteEventMutation();
  const pagination = usePagination({
    size: data?.events.size || 0,
    get: (skip, take) => refetch({ skip, take }),
  });
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", margin: 12 }}>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/admin/events/create");
          }}
        >
          Adicionar
        </Button>
      </div>
      <div style={{ height: 500 }}>
        {/* <DataGrid
          loading={loading}
          rows={data?.events.list || []}
          columns={columns}
          {...pagination.dataGridProps}
        /> */}
        <DataTable
          // loading={loading}
          rows={
            data?.events.list.map((i) => ({
              ...i,
              draft: !i.draft ? "Sim" : "Não",
              date: format(i.date, "dd/MM/yyyy"),
              createdAt: format(i.createdAt, "dd/MM/yyyy"),
            })) || []
          }
          columns={columns}
          onEditClick={(row) => navigate(`/admin/events/${row.id}/edit`)}
          onDeleteClick={async (
            row: Events_EventsQuery["events"]["list"][0]
          ) => {
            setDeleteId(row.id);
          }}
          {...pagination}
        />
      </div>
      <DeleteDialog
        open={!!deleteId}
        onDismiss={() => setDeleteId(undefined)}
        onConfirm={async () => {
          deleteId && (await deleteEvent({ variables: { id: deleteId } }));
          refetch();
        }}
      />
    </>
  );
};

type DeleteDialogProps = {
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => Promise<void>;
};

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  onDismiss,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle>Excluir?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Você realmente deseja excluir este evento e todas suas fotos?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancelar</Button>
        <Button
          onClick={async () => {
            setLoading(true);
            await onConfirm();
            setLoading(false);
            onDismiss();
          }}
        >
          {loading ? <CircularProgress size={18} /> : "Confirmar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
